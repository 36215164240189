import classNames from 'classnames';

export { default } from './Button';
export { default as IconButton } from './IconButton';

export const wrapperClasses = ({
  variant,
  fullWidth,
  size,
  className,
  disabled,
  outlined,
  ariaHidden,
  colour,
}) => {
  return classNames(
    'btn',
    {
      base: variant !== 'clear',
      clear: variant === 'clear',
      danger: colour === 'danger',
      contained: variant === 'contained',
      outlined: variant === 'outlined',
      border: outlined,
      'btn-disabled': disabled && ['outlined', 'clear'].includes(variant),
      'w-full': fullWidth,
      small: size === 'small',
      medium: size === 'medium',
      large: size === 'large',
      'cursor-default': ariaHidden,
    },
    className,
  );
};
