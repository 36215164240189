export default {
  REQUEST_USERS: 'messages/REQUEST_USERS',
  REQUEST_USERS_SUCCESS: 'messages/REQUEST_USERS_SUCCESS',
  REQUEST_ENTITY_MESSAGES_SUCCESS: 'messages/REQUEST_ENTITY_MESSAGES_SUCCESS',
  REQUEST_ENTITY_MESSAGES_FAILURE: 'messages/REQUEST_ENTITY_MESSAGES_FAILURE',
  REQUEST_ENTITY_ROOMS: 'messages/REQUEST_ENTITY_ROOMS',
  REQUEST_ENTITY_ROOMS_SUCCESS: 'messages/REQUEST_ENTITY_ROOMS_SUCCESS',
  REQUEST_FINISHED: 'messages/messages/REQUEST_FINISHED',
  REQUEST_DISCUSSION: 'messages/REQUEST_DISCUSSION',
  REQUEST_DISCUSSION_FAILURE: 'messages/REQUEST_DISCUSSION_FAILURE',
  SET_MESSAGES_CAN_FETCH: 'messages/SET_MESSAGES_CAN_FETCH',
  REMOVE_MESSAGE: 'messages/REMOVE_MESSAGE',
  SET_ENTITY: 'messages/SET_ENTITY',
  SET_MESSAGE_LIKE: 'messages/SET_MESSAGE_LIKE',
  SET_MESSAGE_INSIGHT: 'messages/SET_MESSAGE_INSIGHT',
  SET_MESSAGE_FRESH: 'messages/SET_MESSAGE_FRESH',
};
