import classNames from 'classnames';
import NextImage from 'next/image';
import placeholderImage from '../../public/assets/jpg/placeholder.jpg';

// const urlBuilder = (key, value) => {
//   if (value) {
//     return `${key}_${value},`;
//   }

//   return '';
// };

// const myLoader = ({ src, width, height, quality }) => {
//   return `${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/${urlBuilder('w', width)}${urlBuilder(
//     'h',
//     height,
//   )}q_${quality || 75}/f_auto/${src}`;
// };

export default function Image({ alt, src, className, width, height, layout = 'fill', priority }) {
  const wrapperClasses = classNames(className);
  const photoSource = src || placeholderImage;
  const isPlaceholder = photoSource === placeholderImage;

  // const getLoader = () => {
  //   if (process.env.NEXT_PUBLIC_IMAGE_LOADER !== 'default' && !isPlaceholder) {
  //     return myLoader;
  //   }
  //   return undefined;
  // };
  return (
    <NextImage
      // loader={getLoader()}
      layout={isPlaceholder ? 'intrinsic' : layout}
      alt={alt}
      src={photoSource}
      className={wrapperClasses}
      width={isPlaceholder && !width ? 800 : width}
      height={isPlaceholder && !height ? 800 : height}
      priority={priority}
    />
  );
}
