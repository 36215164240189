import classNames from 'classnames';
import { useState } from 'react';
import { AiFillBulb, AiFillHeart, AiOutlineBulb, AiOutlineHeart } from 'react-icons/ai';
import { IoBookOutline, IoBookSharp } from 'react-icons/io5';
import { useEntity } from '../../../../../lib/entity';
import { useUser } from '../../../../../lib/user';
import { IconButton } from '../../../../Button';

export default function DiscussionReaction({ discussion, altLabels, onLogin }) {
  const {
    state: { user },
  } = useUser();

  const { likeDiscussion, insightDiscussion, freshDiscussion } = useEntity();

  const foundLike = (discussion.likes || []).find((ins) => ins.user_id === user.id) || {
    room_id: discussion.room_id,
    user_id: user.id,
    marked: false,
  };

  const foundInsight = (discussion.insights || []).find((ins) => ins.user_id === user.id) || {
    room_id: discussion.room_id,
    user_id: user.id,
    marked: false,
  };

  const foundFresh = (discussion.freshs || []).find((ins) => ins.user_id === user.id) || {
    room_id: discussion.room_id,
    user_id: user.id,
    marked: false,
  };

  const [like, setLike] = useState(foundLike);
  const [insight, setInsight] = useState(foundInsight);
  const [fresh, setFresh] = useState(foundFresh);

  const handleLike = async () => {
    if (!user.id) {
      return onLogin();
    }
    const { data } = await likeDiscussion({ discussion, user_id: user.id, like });
    if (data) {
      return setLike(data);
    }
    return null;
  };

  const handleInsight = async () => {
    if (!user.id) {
      return onLogin();
    }
    const { data } = await insightDiscussion({
      discussion,
      user_id: user.id,
      insight,
    });
    if (data) {
      return setInsight(data);
    }
    return null;
  };

  const handleFresh = async () => {
    if (!user.id) {
      return onLogin();
    }
    const { data } = await freshDiscussion({ discussion, user_id: user.id, fresh });
    if (data) {
      return setFresh(data);
    }
    return null;
  };

  const actionContainerClassName = classNames('m-icon w-label action');

  const likeContainerClassName = classNames('icn like', {
    liked: like.marked,
  });
  const insightContainerClassName = classNames('icn insight', {
    insighted: insight.marked,
  });

  const freshContainerClassName = classNames('icn fresh', {
    freshed: fresh.marked,
  });

  return (
    <>
      <section className="w-full flex justify-around text-center">
        <IconButton onClick={handleLike} className={actionContainerClassName}>
          <section className={likeContainerClassName}>
            {like.marked ? <AiFillHeart /> : <AiOutlineHeart />}
          </section>
          {!altLabels && <p className="text-sm">I liked it</p>}
          {altLabels && <p className="text-sm">Like</p>}
        </IconButton>
        <IconButton onClick={handleInsight} className={actionContainerClassName}>
          <section className={insightContainerClassName}>
            {insight.marked ? <IoBookSharp /> : <IoBookOutline />}
          </section>
          {!altLabels && <p className="text-sm">It was insightful</p>}
          {altLabels && <p className="text-sm">Insightful</p>}
        </IconButton>
        <IconButton onClick={handleFresh} className={actionContainerClassName}>
          <section className={freshContainerClassName}>
            {fresh.marked ? <AiFillBulb /> : <AiOutlineBulb />}
          </section>
          {!altLabels && <p className="text-sm">It was fresh</p>}
          {altLabels && <p className="text-sm">Fresh</p>}
        </IconButton>
      </section>
    </>
  );
}
