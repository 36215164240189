import { Alert as RAlert } from '@reach/alert';
import classNames from 'classnames';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import Button from '../../components/Button';

const Alert = ({ message, type, action, dismiss }) => (
  <RAlert
    className={classNames('app-message', {
      error: type === 'error',
      success: type === 'success',
    })}
  >
    <Button
      variant="clear"
      className="alert-toast"
      onClick={() => {
        if (action) {
          action();
        }
        dismiss();
      }}
    >
      <section className="alert-wrapper">
        <section>
          {type === 'success' ? (
            <FaCheckCircle className="icon" />
          ) : (
            <FaExclamationCircle className="icon" />
          )}
        </section>
        <section>{message}</section>
        <section>
          <IoClose className="icon" />
        </section>
      </section>
    </Button>
  </RAlert>
);

export default Alert;
