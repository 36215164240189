import classNames from 'classnames';
import isDeepEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useEntity } from '../../../lib/entity';
import capitaliseFirstLetter from '../../../lib/utils/capitaliseFirstLetter';
import { formatNumberShort } from '../../../lib/utils/format';
import Button from '../../Button';
import { Logo } from '../../Icons';
import Image from '../../Image';
import EditRoomModal from '../RoomOptions';

function RoomCard({ room, isRoomPage, side }) {
  const router = useRouter();
  const byId = useEntity().state.entities.byId;
  const entity = byId[room?.entity_id] ?? {};
  const linkTo = `/e/${room.type}/${entity.slug}/${room.slug}`;

  const handleClick = (eve) => {
    if (['room-button', 'room-menu'].includes(eve.target.id)) {
      return null;
    }
    if (eve.metaKey) {
      return window.open(linkTo);
    }
    if (eve.shiftKey) {
      return window.open(
        linkTo,
        '_blank',
        'modal=yes,alwaysRaised=yes,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes',
      );
    }
    return router.push(linkTo);
  };

  const containerClasses = classNames('card room-card', { 'shadow-lg': !side });
  const wrapperClasses = classNames('wrapper');
  const imageContainerClasses = classNames('w-full relative overflow-hidden', {
    'h-48': !isRoomPage,
    'h-20': isRoomPage,
  });
  const contentContainerClasses = classNames('content');
  const profileClasses = classNames('card-profile');

  return (
    <>
      <article className={containerClasses} title={`Go to room: ${room.title}`}>
        <section className="menu-container">
          <EditRoomModal room={room} />
        </section>
        <section className={wrapperClasses}>
          <section className={imageContainerClasses}>
            <Button variant="clear" className="w-full h-full" onClick={handleClick}>
              <Image
                className="w-full h-full object-cover"
                src={room.room_photo || room.entity_photo}
                alt={`${room.title} cover`}
              />
            </Button>
          </section>
          <Button variant="clear" className={contentContainerClasses} onClick={handleClick}>
            <section className="-mt-16">
              <section
                className={profileClasses}
                role="presentation"
                onKeyPress={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <Button as="a" href={`/${room.user?.username}`} variant="clear">
                  <Image
                    className="creator-pic"
                    src={room.user?.photo}
                    alt={`${room.user?.username} profile`}
                    width={48}
                    height={48}
                    layout="fixed"
                  />
                </Button>
              </section>
            </section>
            <section className="title">{room.title}</section>
            <section className="w-full">
              <section
                className="inline-flex flex-col mb-4"
                role="presentation"
                onKeyPress={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  as="a"
                  href={`/e/${room.type}/${entity.slug}`}
                  size="small"
                  title={`Go to ${room.entity_title}`}
                  variant="clear"
                >
                  <p className="uppercase text-xs text-gray-500">
                    {capitaliseFirstLetter(room.type)}
                  </p>
                  <h2 className="line-clamp-1 a line">
                    <span className="sr-only" aria-hidden="true">
                      A
                    </span>
                    {room.entity_title}
                    <span className="sr-only" aria-hidden="true">
                      room about: {room.title} on {process.env.NEXT_PUBLIC_APP_NAME}
                    </span>
                  </h2>
                </Button>
              </section>
              <section className="space-y-4">
                <section className="content-wrapper">
                  {!!room.discussions.length && (
                    <p className="flex items-center">
                      <Logo className="w-4 mr-2" hideTag />{' '}
                      <span className="descriptions">Discussing - {room.discussions[0].title}</span>
                    </p>
                  )}
                  <p className="descriptions">
                    {formatNumberShort(room.users?.[0].count)}{' '}
                    {room.users?.[0].count > 1 || room.users?.[0].count === 0
                      ? 'members'
                      : 'member'}
                  </p>
                </section>
                <Button
                  as="a"
                  href={linkTo}
                  className="w-full uppercase font-bold !py-3 md:!py-2"
                  size="large"
                  id="room-button"
                >
                  View room
                </Button>
              </section>
            </section>
          </Button>
        </section>
      </article>
    </>
  );
}

// RoomCard.whyDidYouRender = true;

export default memo(RoomCard, isDeepEqual);
