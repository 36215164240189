import { enableMapSet } from 'immer';
import { DefaultSeo, LogoJsonLd, SiteLinksSearchBoxJsonLd, SocialProfileJsonLd } from 'next-seo';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { SSRProvider } from 'react-aria';
import { AppLayout } from '../components/Layouts';
import { AlertProvider } from '../lib/alert';
import { AuthProvider } from '../lib/auth';
import usePageAnalytics from '../lib/hooks/usePageAnalytics';
import { NotificationsProvider } from '../lib/notifications';
import { PageProvider } from '../lib/page';
import { UserProvider } from '../lib/user';
import truncateString from '../lib/utils/truncateString';
import '../scripts/wdyr';
import '../styles/globals.css';

enableMapSet();

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export function reportWebVitals(metric) {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    console.log(metric);
  }
}

function App({ Component, pageProps }) {
  const title = truncateString(process.env.NEXT_PUBLIC_APP_DESCRIPTION, 62);
  const getLayout = Component.getLayout || ((page) => <AppLayout>{page}</AppLayout>);

  const router = useRouter();
  useEffect(() => {
    // #region globals (styles, polyfill ...)
    // smoothscroll polyfill
    import('css-scroll-snap-polyfill').then(({ default: smoothScrollPolyfill }) => {
      smoothScrollPolyfill();
    });

    import('smoothscroll-polyfill').then(({ default: scrollSnapPolyfill }) => {
      scrollSnapPolyfill.polyfill();
    });

    // force polyfill (even if browser partially implements it)
    // eslint-disable-next-line no-underscore-dangle
    window.__forceSmoothScrollPolyfill__ = true;
  }, []);

  usePageAnalytics({ analytics: pageProps.analytics, route: router.route, query: router.query });

  const meta = {
    title: encodeURIComponent(
      '**FTVBs - The best place to discuss everything about anything to do with Film & TV.**',
    ),
    photo: encodeURIComponent(`${process.env.NEXT_PUBLIC_DOMAIN}assets/jpg/hero.jpg`),
  };

  return (
    <SSRProvider>
      <DefaultSeo
        title={title}
        canonical={process.env.NEXT_PUBLIC_DOMAIN}
        description={process.env.NEXT_PUBLIC_APP_DESCRIPTION}
        openGraph={{
          type: 'website',
          url: process.env.NEXT_PUBLIC_DOMAIN,
          site_name: process.env.NEXT_PUBLIC_APP_NAME,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_OG_URL}/${meta.title}.png?theme=dark&md=1&fontSize=75px&widths=500&heights=700&images=${meta.photo}&images=${meta.photo}`,
              width: 2048,
              height: 1170,
              alt: `${process.env.NEXT_PUBLIC_APP_NAME} Og Image`,
            },
          ],
        }}
        twitter={{
          handle: '@ftvb_s',
          site: '@ftvb_s',
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              'films chat, movies chat, movieschat, movie chat, moviechat, film chat, tv chat, shows chat, actor chat, cast chat, celebrity chat, episode chat',
          },
        ]}
        dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
        dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
      />
      <LogoJsonLd
        logo={`${process.env.NEXT_PUBLIC_DOMAIN}assets/png/logo.png`}
        url={process.env.NEXT_PUBLIC_DOMAIN}
      />
      <SocialProfileJsonLd
        type="Organization"
        name={process.env.NEXT_PUBLIC_APP_FULL_NAME}
        url={process.env.NEXT_PUBLIC_DOMAIN}
        sameAs={['http://instagram.com/FTVB_s', 'http://twitter.com/FTVB_s']}
      />
      <SiteLinksSearchBoxJsonLd
        url={process.env.NEXT_PUBLIC_DOMAIN}
        potentialActions={[
          {
            target: `${process.env.NEXT_PUBLIC_DOMAIN}e/title?page=1&type=title&filterType=MOVIE&perPage=true&query`,
            queryInput: 'search_term_string',
          },
        ]}
      />
      <UserProvider>
        <PageProvider>
          <AlertProvider>
            <AuthProvider>
              <NotificationsProvider serverUser={pageProps.user}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {getLayout(<Component {...pageProps} />)}
              </NotificationsProvider>
            </AuthProvider>
          </AlertProvider>
        </PageProvider>
      </UserProvider>
    </SSRProvider>
  );
}

export default App;
