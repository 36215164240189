export default function EntitySkeleton() {
  return (
    <section className="entity-card">
      <section className="content-wrapper animate-pulse">
        <section className="image_wrapper bg-gray-800 rounded-lg" />
        <section className="space-y-2">
          <section className="w-20 h-4 bg-gray-800 rounded-lg" />
          <section className="w-12 h-4 bg-gray-800 rounded-lg" />
        </section>
      </section>
    </section>
  );
}
