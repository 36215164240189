import { useRouter } from 'next/router';
import { useAuth } from '../../../lib/auth';
import { useUser } from '../../../lib/user';
import Button from '../../Button';
import DiscussionSidebar from '../../Entities/DiscussionSidebar';
import Trending from '../../Trending';
import { getLayout as getAppLayout } from '../AppLayout';

const DiscoverLayout = ({ children, trending, aside }) => {
  const router = useRouter();
  const { route } = router;

  const { login } = useAuth();
  const {
    state: { user },
  } = useUser();

  const subject = encodeURI('FTVBs App Issue');
  const body = encodeURI('Explaing your issue here:');

  return (
    <>
      <section className="trending">
        {!user.id &&
          route === '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021' && (
            <section className="max-w-screen-lg xl:max-w-screen-xl px-4 mx-auto prose my-8">
              <h1>Discover The Best Trending Film & TV Buffs Live Discussion 2021</h1>
              <p>
                Hello Film & TV Buff lovers, discover the best trending film & TV buffs live
                discussions in 2021. Before you start any discussion, be sure to{' '}
                <Button variant="clear" onClick={login} className="a">
                  sign up
                </Button>{' '}
                to become a permanent discussion member. Its simple here, just join any room for
                your favourite film, TV show or cast, or create a room to start your own
                discussions. For more details, you can check our{' '}
                <Button
                  variant="clear"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCyLjbCkVYXlaMmpvHDycvuA"
                >
                  Youtube channel
                </Button>{' '}
                of FTVBs. Enjoy your best moments with us 🥰
              </p>
              <p>
                We're in BETA right now, so bear with us. If you find any bugs, report them to the{' '}
                <Button
                  variant="clear"
                  as="a"
                  href={`mailto:hi+dev@efosaso.com?subject=${subject}&body=${body}`}
                >
                  support email
                </Button>
                .
              </p>
            </section>
          )}
        {trending}
      </section>
      {children}
      <aside className="sidebar">{aside}</aside>
    </>
  );
};

export const getLayout = (page) =>
  getAppLayout(
    <DiscoverLayout trending={<Trending />} aside={<DiscussionSidebar />}>
      {page}
    </DiscoverLayout>,
  );

export default DiscoverLayout;
