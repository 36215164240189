import classNames from 'classnames';
import { FaDiscord, FaFacebook, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { getSupabase } from '../../../lib/supabase';
import IconButton from '../IconButton';

const providers = {
  facebook: {
    label: 'Facebook',
    icon: <FaFacebook className="w-8 h-8 text-blue-600" />,
    scopes: 'public_profile',
  },
  google: {
    label: 'Google',
    icon: <FcGoogle className="w-8 h-8" />,
    scopes: '',
  },
  discord: {
    label: 'Discord',
    icon: <FaDiscord className="w-8 h-8 text-indigo-400" />,
    scopes: '',
  },
  twitter: {
    label: 'Twitter',
    icon: <FaTwitter className="w-8 h-8 text-blue-400" />,
    scopes: '',
  },
};

export default function SignInButton({
  provider,
  disabled,
  redirectTo,
  variant = 'outlined',
  className,
  size,
}) {
  const wrapperClasses = classNames('login-btn', className);
  const content = providers[provider];

  const handleClick = async () => {
    const supabase = await getSupabase();
    await supabase.auth.signIn(
      {
        provider,
      },
      {
        redirectTo,
        scopes: content.scopes,
      },
    );
  };

  return (
    <IconButton
      variant={variant}
      className={wrapperClasses}
      disabled={disabled}
      onClick={handleClick}
      size={size}
    >
      {content.icon}
      <span className="sr-only">{content.label}</span>
    </IconButton>
  );
}
