import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { BiHomeSmile, BiSearchAlt } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { useAuth } from '../../lib/auth';
import { usePage } from '../../lib/page';
import { useUser } from '../../lib/user';
import truncateString from '../../lib/utils/truncateString';
import { IconButton } from '../Button';
import { Logo } from '../Icons';
import Image from '../Image';
import NavOption from './NavOption';
import Notification from './Notification/Notification';

const SearchFilter = dynamic(() => import('../SearchFilter'));

function Nav() {
  const router = useRouter();
  const { route } = router;

  const { login } = useAuth();
  const {
    state: { user },
  } = useUser();

  const {
    state: { isOpen },
    dispatch,
    actions,
  } = usePage();

  const setIsOpen = (value) => dispatch({ type: actions.OPEN_SEARCH, isOpen: value });

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section id="search" className="nav-search">
        {isOpen && <SearchFilter onClear={() => setIsOpen(false)} autoFocus />}
      </section>
      <section className="nav">
        <section className="container">
          <IconButton
            as="a"
            href={user.id ? '/discover' : '/'}
            variant="clear"
            className="logo-link"
          >
            {route === '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021' && (
              <section className="logo">
                <Image
                  alt="Discover The Trending Best Film & TV Buffs Live Discussion 2021 - FTVBs"
                  width="82"
                  height="82"
                  layout="intrinsic"
                  src="/assets/png/The_Best_Film_&_TV_Buffs_Discussion_For_London_UK_Audience_FTVBs_black.png"
                />
              </section>
            )}
            {route !== '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021' && (
              <Logo pulse={router.route === '/d/[discussionSlug]' && user.id} />
            )}
            {[
              '/',
              '/discover',
              '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021',
              '/e/[type]',
              '/[username]/m/[messageId]',
            ].includes(route) && (
              <h1
                itemScope
                itemType="http://schema.org/Organization"
                className="sr-only"
                aria-hidden="true"
              >
                {truncateString(process.env.NEXT_PUBLIC_APP_DESCRIPTION, 67)}
              </h1>
            )}
            <span className="sr-only">Logo</span>
          </IconButton>
          <NavOption
            active={
              [
                '/discover',
                '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021',
              ].includes(route) && !isOpen
            }
            as="a"
            href={
              !user.id
                ? '/discover-the-best-trending-film-and-tv-buffs-live-discussion-2021'
                : '/discover'
            }
            Icon={BiHomeSmile}
          >
            Discover
          </NavOption>
          <NavOption active={isOpen} onClick={() => setIsOpen(!isOpen)} Icon={BiSearchAlt}>
            Search
          </NavOption>
          <NavOption
            active={route === '/notifications' && !isOpen}
            as={user.id ? 'a' : ''}
            href={user.id ? `/notifications` : ''}
            onClick={() => {
              if (!user.id) {
                login();
              }
            }}
            Icon={Notification}
          >
            Notifications
          </NavOption>
          <NavOption
            active={
              ((user.id && user.username && router.asPath === `/${user.username}`) ||
                router.asPath === '/profile') &&
              !isOpen
            }
            as={user.id && user.username ? 'a' : ''}
            href={user.id && user.username ? `/${user.username}` : ''}
            onClick={() => {
              if (!user.id) {
                login();
              } else if (user.id && !user.username) {
                router.push('/profile');
              }
            }}
            Icon={
              !user.id
                ? FiUser
                : () => (
                    <section className="w-6 h-6 rounded-full overflow-hidden border-2 relative lg:mr-4">
                      <Image
                        src={user.photo}
                        className="h-full w-full object-cover"
                        alt={`${user.name} profile`}
                      />
                    </section>
                  )
            }
          >
            Profile
          </NavOption>
        </section>
      </section>
    </>
  );
}

export default Nav;
