import Link from 'next/link';
import { useRef } from 'react';
import { useButton } from 'react-aria';
import { wrapperClasses } from './index';

export default function Button({ as, disabled, ...props }) {
  const ref = useRef();
  const ButtonEl = as || 'button';
  const { buttonProps: useButtonProps } = useButton(
    {
      ...props,
      elementType: ButtonEl,
      isDisabled: disabled,
    },
    ref,
  );

  const { onPointerDown, ...buttonProps } = useButtonProps;

  const {
    children,
    className,
    variant = 'contained',
    fullWidth,
    size = 'medium',
    href,
    target,
    rel,
    ariaHidden,
    outlined,
    scroll = true,
    title,
    colour,
    tabIndex,
    download,
    prefetch,
    role,
    id,
  } = props;

  const ariaProps = ariaHidden && {
    'aria-hidden': true,
  };

  const classes = wrapperClasses({
    variant,
    fullWidth,
    size,
    className,
    outlined,
    disabled,
    ariaHidden,
    colour,
  });

  if (as === 'a') {
    return (
      <Link href={href} passHref scroll={scroll}>
        <a
          className={classes}
          target={target}
          rel={rel}
          title={title}
          download={download}
          id={id}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...ariaProps}
          tabIndex={tabIndex}
          ref={ref}
          prefetch={prefetch}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={classes}
      title={title}
      id={id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ariaProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      tabIndex={tabIndex}
      role={role}
      ref={ref}
    >
      {children}
    </button>
  );
}
