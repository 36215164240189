import { useEffect, useState } from 'react';
import { getRoomUsers } from '../../../lib/supabase/actions';
import UsersModal from '../UsersModal';

export default function ViewRoomUsersModal({ isOpen, room, onClose }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          const data = await getRoomUsers({ roomId: room.id });
          setUsers(data.users);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [room.id, isOpen]);

  return (
    <UsersModal
      isOpen={isOpen}
      onClose={onClose}
      title={room.title}
      users={users}
      isLoading={isLoading}
    />
  );
}
