import { useRouter } from 'next/router';
import { useState } from 'react';
import { FiEdit, FiLink2, FiMoreHorizontal, FiShare, FiTrash, FiUsers } from 'react-icons/fi';
import { RiChatVoiceLine } from 'react-icons/ri';
import { useAlert } from '../../../lib/alert';
import useAuth from '../../../lib/auth/useAuth';
import { useEntity } from '../../../lib/entity';
import { useUser } from '../../../lib/user';
import slugify from '../../../lib/utils/slugify';
import Button from '../../Button';
import Menu from '../../Menus/Menu';
import Modal from '../../Modals/Modal';
import ViewRoomDiscussionsModal from '../../Modals/ViewRoomDiscussionsModal/ViewRoomDiscussionsModal';
import ViewRoomUsersModal from '../../Modals/ViewRoomUsersModal/ViewRoomUsersModal';
import { Edit as EditRoomForm } from '../Forms';

export default function RoomOptions({ room }) {
  const router = useRouter();
  const byId = useEntity().state.entities.byId;
  const removeRoom = useEntity().removeRoom;
  const dispatch = useEntity().dispatch;

  const user = useUser().state.user;
  const handleMessage = useAlert().handleMessage;

  const login = useAuth().login;

  const [editIsOpen, setEditIsOpen] = useState(false);
  const [isRoomUsersOpen, setIsRoomUsersOpen] = useState(false);
  const [isRoomDiscussionsOpen, setIsRoomDiscussionsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const entity = byId[room?.entity_id] ?? {};
  const linkTo = `/e/${room?.type}/${entity.slug}/${room?.slug}`;
  const canEdit = user.username === room?.user?.username;
  const canDelete =
    user.username === room?.user?.username &&
    room?.messages_count?.[0].count === 0 &&
    room?.discussions_count?.[0].count === 0;

  const handleEditClose = () => setEditIsOpen(false);

  const handleConfirmClose = () => {
    if (!isLoading) {
      setConfirmIsOpen(false);
    }
  };

  const onRemove = async () => {
    setIsLoading(true);
    try {
      const response = await removeRoom(dispatch, room);
      const path = `/e/${response.room.type}/${slugify(response.room.entity_title)}-${
        response.room.entity_id
      }/${response.room.slug}`;
      if (router.asPath === path) {
        await router.replace(
          `/e/${response.room.type}/${slugify(response.room.entity_title)}-${
            response.room.entity_id
          }`,
        );
      }
    } catch (error) {
      console.log('🥵 ~ error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = async () => {
    const shareLink = `${window.location.origin}${linkTo}`;

    if (navigator.share) {
      await navigator.share({
        title: window.location.hostname,
        text: `Join the discussion for ${room.entity_title}: "${room.title}" on ${process.env.NEXT_PUBLIC_APP_DESCRIPTION}`,
        url: shareLink,
      });
    } else {
      navigator.clipboard.writeText(shareLink);
      handleMessage({
        message: {
          message: 'Room link copied!',
          type: 'success',
        },
      });
    }
  };

  const handleViewUsers = () => setIsRoomUsersOpen(true);

  const handleViewDiscussions = () => setIsRoomDiscussionsOpen(true);
  const handleViewDiscussionsClose = () => setIsRoomDiscussionsOpen(false);

  const handleLogin = () => {
    handleViewDiscussionsClose();
    return login();
  };

  const options = [
    {
      key: 'share-room',
      name: navigator.share !== undefined ? 'Share room' : 'Copy room link',
      onClick: handleShare,
      icon: navigator.share !== undefined ? FiShare : FiLink2,
    },
    {
      key: 'view-room-users',
      name: 'View room users',
      onClick: handleViewUsers,
      icon: FiUsers,
    },
    {
      key: 'view-discussions',
      name: 'View past discussions',
      onClick: handleViewDiscussions,
      icon: RiChatVoiceLine,
    },
    ...(canEdit
      ? [
          {
            key: 'edit-room',
            name: 'Edit room',
            onClick: () => setEditIsOpen(true),
            icon: FiEdit,
          },
        ]
      : []),
    ...(canDelete
      ? [
          {
            key: 'remove-room',
            name: 'Remove room',
            onClick: () => setConfirmIsOpen(true),
            icon: FiTrash,
          },
        ]
      : []),
  ];
  return (
    <>
      <Menu id="room-menu" options={options} type="button" className="wrapper" position="right">
        <FiMoreHorizontal className="icon" />
        <span className="sr-only">Room options</span>
      </Menu>
      <EditRoomForm isOpen={editIsOpen} onClose={handleEditClose} room={room} />
      <Modal isOpen={confirmIsOpen} onClose={handleConfirmClose} transparent>
        <section className="bg-white rounded-lg shadow-lg p-8 flex flex-col space-y-8 items-end">
          <section className="space-y-4 w-full">
            <h3 className="text-2xl font-bold">Are you sure you want to remove this room?</h3>
            <p>This cannot be undone</p>
          </section>
          <Button disabled={isLoading} onClick={onRemove} colour="danger" className="w-max">
            Remove room
          </Button>
        </section>
      </Modal>
      <ViewRoomUsersModal
        isOpen={isRoomUsersOpen}
        onClose={() => setIsRoomUsersOpen(false)}
        room={room}
      />
      <ViewRoomDiscussionsModal
        isOpen={isRoomDiscussionsOpen}
        onLogin={handleLogin}
        onClose={handleViewDiscussionsClose}
        room={room}
      />
    </>
  );
}
