import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Modal from '../../components/Modals/Modal';
import { useUser } from '../user';

export const PageContext = createContext();

const actions = {
  REQUEST: 'page/REQUEST',
  REQUEST_SEARCH: 'page/REQUEST_SEARCH',
  REQUEST_PAGE: 'page/REQUEST_PAGE',
  OPEN_SEARCH: 'page/OPEN_SEARCH',
};

const initialState = () => ({
  isOpen: false,
  isSearching: false,
  isLoading: false,
});

function pageReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.REQUEST_SEARCH: {
      const { isSearching } = action;
      return { ...state, isSearching };
    }
    case actions.OPEN_SEARCH: {
      const { isOpen } = action;
      return { ...state, isOpen };
    }
    case actions.REQUEST_PAGE: {
      const { isLoading } = action;
      return { ...state, isLoading };
    }
    default: {
      return state;
    }
  }
}

export default function PageProvider({ children }) {
  const [state, dispatch] = useReducer(pageReducer, initialState());
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = useMemo(() => ({ state, dispatch }), [state]);

  const [hideNav, setHideNav] = useState(false);
  const [hideAside, setHideAside] = useState(false);
  const [hideHero, setHideHero] = useState(false);
  const [navHeight, setNavHeight] = useState(0);
  const [hideSide, setHideSide] = useState(false);
  const [discussion, setDiscussion] = useState(null);
  const classes = useRef({});

  const user = useUser().state.user;

  const router = useRouter();
  const { route } = router;
  const redirectTo = router.query?.redirectTo;
  const isNotificationsRedirect = redirectTo && redirectTo.includes('notifications');
  const error = router.query?.error;

  useEffect(() => {
    if (!redirectTo && user.id && route === '/') {
      router.replace('/discover');
    }
  }, [redirectTo, route, router, user.id]);

  useEffect(() => {
    if (redirectTo && !error) {
      if (isNotificationsRedirect) {
        if (user.id) {
          router.push(redirectTo);
        }
      } else {
        router.push(redirectTo);
      }
    }
  }, [error, isNotificationsRedirect, redirectTo, router, user.id]);

  return (
    <PageContext.Provider
      value={{
        ...value,
        actions,
        discussion,
        setDiscussion,
        hideNav,
        setHideNav,
        hideHero,
        setHideHero,
        hideSide,
        setHideSide,
        hideAside,
        setHideAside,
        navHeight,
        setNavHeight,
        classes,
      }}
    >
      <Modal
        isOpen={(!!redirectTo && redirectTo !== '/') || (!!user.id && route === '/')}
        size="xs"
      >
        <section className="p-8">
          <h1 className="text-2xl font-bold">One moment please</h1>
          <p className="text-gray-600">Continuing where you left off...</p>
        </section>
      </Modal>
      {children}
    </PageContext.Provider>
  );
}
