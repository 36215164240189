import { useContext } from 'react';
import EntityContext from './EntityContext';

const useEntity = () => {
  const context = useContext(EntityContext);

  if (context === undefined) {
    throw new Error('useEntity must be used within a EntityContext.Provider');
  }

  return context;
};

export default useEntity;
