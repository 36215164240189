import actionTypes from './actionTypes';

const createState = (all, next) => {
  if (!all.byId[next.id]) {
    all.ids.push(next.id);
    all.byId[next.id] = next;
  }
  return all;
};

const newState = () => ({
  ids: [],
  byId: {},
});

export const initialState = () => ({
  isLoading: true,
  notifications: newState(),
  canFetchMore: true,
  users: {},
});

function messagesReducer(state = initialState(), action) {
  switch (action.type) {
    case actionTypes.REQUEST_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionTypes.REQUEST_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.REQUEST_NOTIFICATIONS_SUCCESS: {
      const { notifications } = action;

      return {
        ...state,
        isLoading: false,
        notifications: notifications.reduce(createState, { ...state.notifications }),
      };
    }
    case actionTypes.ADD_NOTIFICATION: {
      const { notification } = action;
      const { byId, ids } = state.notifications;
      if (!byId[notification.id]) {
        ids.unshift(notification.id);
      }
      byId[notification.id] = notification;

      return {
        ...state,
        notifications: {
          byId,
          ids,
        },
      };
    }
    case actionTypes.REMOVE_NOTIFICATION: {
      const { notificationId } = action;
      const { [notificationId]: _, ...byId } = state.notifications.byId;

      return {
        ...state,
        notifications: {
          byId,
          ids: state.notifications.ids.filter((id) => id !== notificationId),
        },
      };
    }
    case actionTypes.SET_USER: {
      const { user } = action;
      const { users } = state;
      users[user.id] = user;

      return {
        ...state,
        users,
      };
    }
    default: {
      return state;
    }
  }
}

export default messagesReducer;
