import classNames from 'classnames';
import React from 'react';

export default function Skeleton({ layout }) {
  const wrapperClasses = classNames('base-card animate-pulse', layout);
  return (
    <section className={wrapperClasses}>
      <section className="wrapper">
        <section className="content">
          <section className="heading">
            <section className="rounded-full w-40 h-2" />
          </section>
          <section className="rounded-full w-11/12 h-2" />
          <section className="rounded-full w-8/12 h-2" />
        </section>
      </section>
    </section>
  );
}
