import { getSupabase } from '../supabase';
import authFetch from '../utils/authFetch';
import actionTypes from './actionTypes';

export const findOrCreateUser = async (dispatch, data) => {
  const supabase = await getSupabase();

  const {
    id,
    username,
    email,
    user_metadata: { full_name: name, avatar_url: photo },
  } = data;

  dispatch({ type: actionTypes.REQUEST_USER });

  const { body } = await supabase
    .from('users')
    .select('id,username,bio,photo,name,social_links')
    .eq('id', id)
    .limit(1);

  let user = body?.[0] || {};

  if (!user.id) {
    const { data: userData, error } = await supabase
      .from('users')
      .insert([
        {
          id,
          username,
          photo,
          email,
          name,
          status: 'ONLINE',
        },
      ])
      .select('id,username,name,bio,photo,social_links')
      .single();
    if (!error) {
      user = userData;
    }
  }

  dispatch({ type: actionTypes.SET_USER, user });

  return { user };
};

export const updateUser = async (dispatch, details) => {
  const supabase = await getSupabase();

  dispatch({ type: actionTypes.REQUEST_USER });

  const { data: user } = await supabase
    .from('users')
    .update(details)
    .select('id,username,bio,photo,name,social_links')
    .single();

  dispatch({ type: actionTypes.SET_USER, user });

  return user;
};

export const requestUpdateUser = async (dispatch, addDetails, options) =>
  dispatch({ type: actionTypes.REQUEST_USER_DETAILS, addDetails, options });

export const requestUpdateName = async (dispatch) =>
  dispatch({ type: actionTypes.REQUEST_USER_NAME });

export const signInWithProvider = async (provider) => {
  const supabase = await getSupabase();

  return supabase.auth.signIn({ provider });
};

export const signOut = async (dispatch) => {
  const supabase = await getSupabase();

  const { error } = await supabase.auth.signOut();
  if (!error) {
    dispatch({ type: actionTypes.SET_USER, user: {} });
  }
};

export const setServerSession = async (event, session) => {
  await authFetch('/api/auth', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ event, session }),
  });
};
