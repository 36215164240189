export default function resizeMe(img) {
  const canvas = document.createElement('canvas');
  let width = img.width;
  let height = img.height; // calculate the width and height, constraining the proportions

  if (width > height) {
    if (width > 1024) {
      // height *= 1024 / width;
      height = Math.round((height *= 1024 / width));
      width = 1024;
    }
  } else if (height > 512) {
    // width *= 512 / height;
    width = Math.round((width *= 512 / height));
    height = 512;
  } // resize the canvas and draw the image data into it

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
}
