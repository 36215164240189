import { createContext, useMemo, useReducer } from 'react';

// import { ROUTE_HOME, ROUTE_AUTH } from '../../config';

export const AuthContext = createContext();

const actions = {
  SET_AUTH_VIEW: 'auth/SET_AUTH_VIEW',
};

const initialState = () => ({
  isAuthOpen: false,
  authType: '',
});

function authReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.SET_AUTH_VIEW: {
      const { isAuthOpen, authType } = action;

      if (authType) {
        return { ...state, isAuthOpen, authType };
      }

      return { ...state, isAuthOpen };
    }
    default: {
      return state;
    }
  }
}
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState());
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = useMemo(() => ({ state, dispatch }), [state]);

  const login = () =>
    dispatch({ type: actions.SET_AUTH_VIEW, isAuthOpen: true, authType: 'sign_in' });

  const signUp = () =>
    dispatch({ type: actions.SET_AUTH_VIEW, isAuthOpen: true, authType: 'sign_up' });

  const openAuth = () => dispatch({ type: actions.SET_AUTH_VIEW, isAuthOpen: true });
  const closeAuth = () => dispatch({ type: actions.SET_AUTH_VIEW, isAuthOpen: false });
  return (
    <AuthContext.Provider value={{ ...value, login, signUp, openAuth, closeAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

// AuthProvider.whyDidYouRender = true;

export default AuthProvider;
