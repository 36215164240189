import isDeepEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import { useAlert } from '../alert';
import { useEntity } from '../entity';
import { getSupabase, PER_PAGE } from '../supabase';
import { getEntityRooms } from '../supabase/actions';
import { deleteMessage } from '../supabase/actions/messages';
import { getUser } from '../supabase/actions/users';
import { useUser } from '../user';
import authFetch from '../utils/authFetch';
import { parseUTC } from '../utils/format';
import getIdFromSlug from '../utils/getIdFromSlug';
import actionTypes from './actionTypes';
import MessagesContext from './MessagesContext';
import messagesReducer, { initialState } from './messagesReducer';

const MessagesProvider = ({ children, load, profile = {} }) => {
  const handleMessage = useAlert().handleMessage;

  const entityRoom = useEntity().room;
  const rooms = useEntity().rooms;
  const setHasMessages = useEntity().setHasMessages;
  const entityRooms = useEntity().state.entityRooms;
  const entities = useEntity().state.entities;

  const router = useRouter();
  const user = useUser().state.user;

  const { route } = router;
  const { roomSlug, entitySlug, discussionSlug, messageId, username } = router.query;

  const entityId = getIdFromSlug(entitySlug);
  const discussionId = getIdFromSlug(discussionSlug);
  const newMessagesRef = useRef([]);
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [state, dispatch] = useImmerReducer(messagesReducer, initialState());

  const value = useMemo(() => ({ state, dispatch }), [dispatch, state]);

  const messagesState = value.state;
  const messagesDispatch = value.dispatch;

  const message = messagesState.messages.byId[messageId] ?? {};

  const getEntityFromState = (newEntityId) => {
    if (newEntityId) {
      return entities.byId[newEntityId];
    }

    return {};
  };

  const getRoomFromState = useCallback(
    (newRoomId) => {
      if (message.id && message.room) {
        return entityRooms[message.room.entity_id]?.rooms.byId[message.room_id] ?? {};
      }
      if (newRoomId) {
        return Object.keys(entityRooms).reduce((all, id) => {
          const foundRoom = entityRooms[id].rooms.byId[newRoomId];
          if (foundRoom) {
            return foundRoom;
          }
          return all;
        }, {});
      }

      return entityRoom ?? {};
    },
    [entityRoom, entityRooms, message.id, message.room, message.room_id],
  );

  const room = getRoomFromState();

  const queryRoomId = getIdFromSlug(roomSlug);

  const roomId = room.id ?? parseInt(queryRoomId, 10);

  const isUsersProfile = username === profile.username && route === '/[username]';

  const listnersRoomId = message.room_id ?? roomId;

  const allMessages = messagesState.messages.ids.map((id) => messagesState.messages.byId[id]);

  const messageChildren = allMessages.filter((m) => m.parent_id === message.id);
  const parentMessage = messagesState.messages.byId[message.parent_id] ?? {};
  const parentChildren = allMessages.filter((m) => m.parent_id === parentMessage.id);

  const entityMessages = allMessages.reduce((all, next) => {
    if (next?.room?.entity_id === entityId) {
      all.push(next);
    }
    return all;
  }, []);

  const userMessages = allMessages.reduce((all, next) => {
    if (next?.user_id === profile.id && !next.is_deleted) {
      all.push(next);
    }
    return all;
  }, []);

  const roomMessages = allMessages.reduce((all, next) => {
    if (next.room_id === roomId && !next.is_deleted) {
      all.push(next);
    }
    return all;
  }, []);

  const discussionMessages = allMessages.reduce((all, next) => {
    if (next.room_id === room.id) {
      all.push(next);
    }
    return all;
  }, []);

  const getMessages = () => {
    if (discussionId) {
      return discussionMessages;
    }
    if (roomId) {
      return roomMessages;
    }
    if (entityId) {
      return entityMessages;
    }
    if (profile.id) {
      return userMessages;
    }

    return allMessages;
  };

  const messages = getMessages().sort((a, b) => parseUTC(b.inserted_at) - parseUTC(a.inserted_at));

  useEffect(() => {
    if (route === '/[username]' && profile.id) {
      (async () => {
        try {
          await fetchUserMessages(messagesDispatch, profile.id);
        } catch (error) {
          console.log('🥵 ~ error', error);
        }
      })();
    }
  }, [messagesDispatch, profile.id, route]);

  useEffect(() => {
    messagesDispatch({ type: actionTypes.REQUEST_ENTITY_ROOMS });
    if (route === '/e/[type]/[entitySlug]' && rooms.length && load) {
      (async () => {
        try {
          const data = await fetchEntityRooms(messagesDispatch, entityId);
          await fetchEntityMessages(
            messagesDispatch,
            data,
            { rangeFrom: 0, rangeTo: PER_PAGE },
            messagesState.users,
          );
        } finally {
          messagesDispatch({ type: actionTypes.REQUEST_FINISHED });
        }
      })();
    } else {
      messagesDispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS, messages: [] });
      messagesDispatch({ type: actionTypes.REQUEST_FINISHED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, rooms.length, route, load]);

  // Update when the route changes
  useEffect(() => {
    if (roomId && !['/e/[type]/[entitySlug]', '/[username]/m/[messageId]'].includes(route)) {
      (async () => {
        messagesDispatch({ type: actionTypes.REQUEST_ENTITY_ROOMS });

        await fetchEntityMessages(
          messagesDispatch,
          [roomId],
          { rangeFrom: 0, rangeTo: PER_PAGE },
          messagesState.users,
        );

        messagesDispatch({ type: actionTypes.REQUEST_FINISHED });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, roomId, route]);

  // Load initial data and set up listeners
  useEffect(() => {
    const getMessagesFrom = () => {
      if (isUsersProfile && user.id) {
        return `messages:user_id=eq.${user.id}`;
      }
      if (listnersRoomId) {
        return `messages:room_id=eq.${listnersRoomId}`;
      }

      return 'messages';
    };
    const from = getMessagesFrom();

    let messageListener;

    (async () => {
      const supabase = await getSupabase();

      // Listen for new messages
      messageListener = supabase
        .from(from)
        .on('INSERT', async (payload) => {
          const newMessage = payload.new;
          newMessage.likes = [];
          newMessage.insights = [];
          newMessage.freshs = [];
          let userProfile = newMessage.user ?? messagesState.users[payload.new.user_id];
          if (!userProfile) {
            const userRepsonse = await getUser({ userId: payload.new.user_id });
            userProfile = userRepsonse.user;
          }
          newMessage.user = userProfile;
          if (route !== '/e/[type]/[entitySlug]') {
            return messagesDispatch({
              type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS,
              messages: [newMessage],
            });
          }
          const newMessageRoom = getRoomFromState(newMessage.room_id);
          const newMessageEntity = getEntityFromState(newMessageRoom.entity_id);
          if (router.asPath === `/e/${newMessageEntity.e_type}/${newMessageEntity.slug}`) {
            newMessage.room = newMessageRoom;
            newMessagesRef.current.unshift(newMessage);
            let messageText = 'New posts';
            if (newMessagesRef.current.length > 1) {
              messageText = `See ${newMessagesRef.current.length} new posts`;
            }
            return handleMessage({
              dismissable: false,
              message: {
                message: messageText,
                type: 'success',
                action: () => {
                  messagesDispatch({
                    type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS,
                    messages: newMessagesRef.current,
                  });
                  newMessagesRef.current = [];
                  if (route !== '/[username]/m/[messageId]') {
                    messagesStartRef.current.scrollIntoView({
                      block: 'start',
                      inline: 'nearest',
                      behavior: 'smooth',
                    });
                  }
                },
              },
            });
          }
          return null;
        })
        .subscribe();
    })();

    // Cleanup on unmount
    return () => {
      if (messageListener) {
        messageListener.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const getLikesFrom = () => {
    if (roomId) {
      return `message_likes:room_id=eq.${roomId}`;
    }
    return 'message_likes';
  };
  const likesFrom = getLikesFrom();

  const getInsightfulFrom = () => {
    if (roomId) {
      return `message_insightful:room_id=eq.${roomId}`;
    }
    return 'message_insightful';
  };

  const insightfulFrom = getInsightfulFrom();

  const getFreshFrom = () => {
    if (roomId) {
      return `message_fresh:room_id=eq.${roomId}`;
    }
    return 'message_fresh';
  };

  const freshFrom = getFreshFrom();

  useEffect(() => {
    let likesListener;
    let insightfulListener;
    let freshListener;

    (async () => {
      const supabase = await getSupabase();

      // Listen for new likes
      likesListener = supabase
        .from(likesFrom)
        .on('INSERT', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_LIKE,
            like: payload.new,
          });
        })
        .on('UPDATE', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_LIKE,
            like: payload.new,
          });
        })
        .subscribe();
      // Listen for new insightful
      insightfulListener = supabase
        .from(insightfulFrom)
        .on('INSERT', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_INSIGHT,
            insight: payload.new,
          });
        })
        .on('UPDATE', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_INSIGHT,
            insight: payload.new,
          });
        })
        .subscribe();

      // Listen for new fresh
      freshListener = supabase
        .from(freshFrom)
        .on('INSERT', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_FRESH,
            fresh: payload.new,
          });
        })
        .on('UPDATE', (payload) => {
          messagesDispatch({
            type: actionTypes.SET_MESSAGE_FRESH,
            fresh: payload.new,
          });
        })
        .subscribe();
    })();

    // Cleanup on unmount
    return () => {
      if (
        !['/e/[type]/[entitySlug]', '/e/[type]/[entitySlug]/r/[roomId]', '/[username]'].includes(
          route,
        )
      ) {
        if (likesListener) {
          likesListener.unsubscribe();
        }
        if (insightfulListener) {
          insightfulListener.unsubscribe();
        }
        if (freshListener) {
          freshListener.unsubscribe();
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshFrom, insightfulFrom, likesFrom, route]);

  useEffect(() => {
    if (messages.length !== 0) {
      setHasMessages(true);
    }
  }, [messages, setHasMessages]);

  return (
    <MessagesContext.Provider
      value={{
        state: messagesState,
        dispatch: messagesDispatch,
        freshMessage,
        insightMessage,
        likeMessage,
        addMessage,
        fetchUserMessages,
        fetchEntityMessages,
        message,
        messagesRoom: room,
        messageChildren,
        parentMessage,
        parentChildren,
        messages,
        messagesStartRef,
        messagesEndRef,
        setMessage,
        setMessages,
        removeMessage,
        currentProfile: profile,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

// MessagesProvider.whyDidYouRender = true;

export default memo(MessagesProvider, isDeepEqual);

/**
 * Fetch messages for user
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchUserMessages = async (
  dispatch,
  userId,
  { rangeFrom, rangeTo } = { rangeFrom: 0, rangeTo: PER_PAGE },
) => {
  try {
    const { messages } = await authFetch('/api/user-messages', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        rangeFrom,
        rangeTo,
      }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    });
    if (messages.length < PER_PAGE) {
      if (rangeFrom === 0) {
        dispatch({ type: actionTypes.REQUEST_USERS_SUCCESS, user: messages[0].user });
      }
      dispatch({ type: actionTypes.SET_MESSAGES_CAN_FETCH, canFetchMore: false });
    } else {
      dispatch({ type: actionTypes.SET_MESSAGES_CAN_FETCH, canFetchMore: true });
    }
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS, messages });
    return messages;
  } catch (error) {
    console.error('error', error);
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_FAILURE });
    return error;
  }
};

/**
 * Fetch messages for room
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchEntityMessages = async (
  dispatch,
  rooms,
  { rangeFrom, rangeTo } = { rangeFrom: 0, rangeTo: PER_PAGE },
  users,
) => {
  try {
    const { messages } = await authFetch('/api/room-messages', {
      method: 'POST',
      body: JSON.stringify({
        rooms,
        rangeFrom,
        rangeTo,
      }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    });
    if (messages.length < PER_PAGE) {
      dispatch({ type: actionTypes.SET_MESSAGES_CAN_FETCH, canFetchMore: false });
    } else {
      dispatch({ type: actionTypes.SET_MESSAGES_CAN_FETCH, canFetchMore: true });
    }
    messages.forEach(({ user }) => {
      if (!users[user.id]) {
        dispatch({ type: actionTypes.REQUEST_USERS_SUCCESS, user });
      }
    });
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS, messages });
    return messages;
  } catch (error) {
    console.error('error', error);
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_FAILURE });
    return error;
  }
};

/**
 * Fetch messages for room
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchEntityRooms = async (dispatch, entityId) => {
  try {
    const { rooms } = await getEntityRooms({ entityId, select: 'id, entity_id, title' });
    return rooms.map(({ id }) => id);
  } catch (error) {
    console.error('error', error);
    return error;
  }
};

/**
 * Fetch messages for room
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const setMessage = async (dispatch, message) => {
  if (!message.is_deleted) {
    dispatch({ type: actionTypes.REQUEST_USERS_SUCCESS, user: message.user });
  }
  dispatch({
    type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS,
    messages: [message],
  });
};

/**
 * Fetch messages for room
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const setMessages = async (dispatch, messages, users) => {
  messages.forEach((message) => {
    if (!message.is_deleted && !users[message.user.id]) {
      dispatch({ type: actionTypes.REQUEST_USERS_SUCCESS, user: message.user });
    }
  });

  dispatch({
    type: actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS,
    messages,
  });
};

/**
 * Insert a new message into the DB
 * @param {string} message The message text
 * @param {number} room_id
 * @param {number} user_id The user
 */
export const addMessage = async (dispatch, message) => {
  try {
    const supabase = await getSupabase();

    const data = await supabase.from('messages').insert([message]);
    return data;
  } catch (error) {
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_FAILURE });
    console.log('error', error);
    return { error };
  }
};

/**
 * Insert a new liked message into the DB
 * @param {object} message The message
 * @param {number} user_id The user id
 * @param {object} like The like object
 */
export const likeMessage = async ({ message, user_id, like }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (like?.message_id) {
      const response = await supabase
        .from('message_likes')
        .update({ marked: !like.marked })
        .match({ message_id: message.id, room_id: message.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('message_likes')
        .upsert([{ message_id: message.id, room_id: message.room_id, user_id, marked: true }])
        .select('*')
        .single();
      data = response.data;
    }
    return data;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

/**
 * Insert a new insight message into the DB
 * @param {object} message The message
 * @param {number} user_id The user id
 * @param {object} insight The insight object
 */
export const insightMessage = async ({ message, user_id, insight }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (insight?.message_id) {
      const response = await supabase
        .from('message_insightful')
        .update({ marked: !insight.marked })
        .match({ message_id: message.id, room_id: message.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('message_insightful')
        .upsert([{ message_id: message.id, room_id: message.room_id, user_id, marked: true }])
        .select('*')
        .single();
      data = response.data;
    }
    return data;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
/**
 * Insert a new fresh message into the DB
 * @param {object} message The message
 * @param {number} user_id The user id
 * @param {object} fresh The fresh object
 */
export const freshMessage = async ({ message, user_id, fresh }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (fresh?.message_id) {
      const response = await supabase
        .from('message_fresh')
        .update({ marked: !fresh.marked })
        .match({ message_id: message.id, room_id: message.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('message_fresh')
        .upsert([{ message_id: message.id, room_id: message.room_id, user_id, marked: true }])
        .select('*')
        .single();
      data = response.data;
    }
    return data;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

/**
 * Remove message from the DB
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 * @param {object} message The message id
 */
export const removeMessage = async (dispatch, message) => {
  try {
    const messagesResponse = await deleteMessage(message.id);
    if (dispatch) {
      dispatch({ type: actionTypes.REMOVE_MESSAGE, message: messagesResponse.message });
    }
    return messagesResponse;
  } catch (error) {
    dispatch({ type: actionTypes.REQUEST_ENTITY_MESSAGES_FAILURE });
    console.error('error', error);
    return { error };
  }
};
