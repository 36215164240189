import React, { useRef, useState } from 'react';
import { FiImage } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import filesize from '../../../lib/utils/filesize';
import { IconButton } from '../../Button';

const FileInput = ({
  className,
  name,
  label,
  accept,
  multiple,
  errors = [],
  onChange,
  disabled,
  max = 4,
}) => {
  const fileInput = useRef();
  const [file, setFile] = useState(null);

  const browse = () => fileInput.current.click();

  function remove() {
    setFile(null);
    onChange(null);
    fileInput.current.value = null;
  }

  function handleFileChange(e) {
    let newFile = multiple ? e.target.files : e.target.files[0];
    if (multiple && e.target.files.length > max) {
      newFile = Array.from(e.target.files);

      alert(`Maximum media limit is ${max}. Only the first 4 will be used.`);
      newFile.splice(3, e.target.files.length - max);
    }
    setFile(newFile);
    return onChange(newFile);
  }

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}:
        </label>
      )}
      <div className={`form-input${errors.length ? ' error' : ''}`}>
        <input
          id={name}
          ref={fileInput}
          accept={accept}
          multiple={multiple}
          type="file"
          className="hidden"
          onChange={handleFileChange}
          disabled={disabled}
        />
        {(!file || multiple) && (
          <IconButton className="file-input-add" disabled={disabled} onClick={browse}>
            <FiImage />
            <span className="sr-only">Select</span>
          </IconButton>
        )}
        {file && !multiple && (
          <div className="flex flex-wrap items-center justify-between p-2">
            <div className="flex-1 pr-1">
              {file.name}
              <span className="ml-1 text-xs text-gray-600">({filesize(file.size)})</span>
            </div>
            <IconButton onClick={remove}>
              <IoClose className="w-6 h-6" />
              <span className="sr-only">Remove</span>
            </IconButton>
          </div>
        )}
      </div>
      {!!errors.length && <div className="form-error">{errors}</div>}
    </div>
  );
};

export default FileInput;
