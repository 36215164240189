import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import { IconButton } from '../../Button';

export default function Modal({
  title,
  isOpen,
  onClose,
  children,
  actions,
  transparent,
  hideClose,
  size = 'sm',
}) {
  const cancelButtonRef = useRef();

  const modalContentWrapperClasses = classNames('modal-content', size, {
    'bg-white shadow-xl rounded-lg': !transparent,
  });

  const closeButtonClasses = classNames('modal-close', {
    'focus:border-none': !transparent,
    white: !onClose,
  });

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="section"
        id="modal"
        className="modal"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={closeModal}
      >
        <section className="modal-container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="overlay" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={modalContentWrapperClasses}>
              {title && (
                <Dialog.Title as="h3" className="title">
                  {title}
                </Dialog.Title>
              )}
              {children}

              {actions && <div className="modal-actions">{actions.map((action) => action)}</div>}
              {!hideClose && (
                <IconButton className={closeButtonClasses} onClick={closeModal} variant="light">
                  <IoClose className="icon" />
                </IconButton>
              )}
            </div>
          </Transition.Child>
        </section>
      </Dialog>
    </Transition>
  );
}
