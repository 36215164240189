import siteSocials from '../../lib/consts/siteSocials';
import Button from '../Button';

const links = [
  {
    href: '/terms',
    label: 'Terms',
  },
  {
    href: '/privacy',
    label: 'Privacy',
  },
  {
    href: '/about',
    label: 'About',
  },
];

export default function Footer() {
  return (
    <footer>
      <section className="wrapper">
        {links.map((link) => (
          <Button key={link.label} as="a" variant="clear" href={link.href}>
            {link.label}
          </Button>
        ))}
        <p>
          © {new Date().getUTCFullYear()} {process.env.NEXT_PUBLIC_APP_NAME}.
        </p>
        <section className="w-full place-content-center md:w-auto mt-8 md:mt-0 flex space-x-10 md:space-x-6">
          {siteSocials.map((social) => (
            <Button
              key={social.label}
              as="a"
              href={social.link}
              variant="clear"
              target="_blank"
              rel="noreferrer noopenner"
            >
              {social.icon}
              <span className="sr-only">{social.label}</span>
            </Button>
          ))}
        </section>
      </section>
    </footer>
  );
}
