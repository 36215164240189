import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useAlert } from '../../../../lib/alert';
import { useEntity } from '../../../../lib/entity';
import { allDiscussionsSQL, getSupabase, userDetails } from '../../../../lib/supabase';
import { uploadFile } from '../../../../lib/supabase/actions';
import { useUser } from '../../../../lib/user';
import resizeMe from '../../../../lib/utils/resizeMe';
import slugify from '../../../../lib/utils/slugify';
import Button, { IconButton } from '../../../Button';
import FileInput from '../../../FormFields/FileInput/FileInput';
import TextInput from '../../../FormFields/TextInput';
import { Spinner } from '../../../Icons';
import Modal from '../../../Modals/Modal';

const errorsInitialState = {
  title: '',
  created_by: '',
  entity_id: '',
  room_photo: '',
};

const roomInitialState = {
  title: '',
  created_by: '',
  entity_id: '',
  room_photo: '',
};

function getSlug({ title, user, file }) {
  const fileExt = file.name.split('.').pop();

  return `${user.id}/rooms/${slugify(title)}-${user.username}.${fileExt}`;
}

export default function CreateRoomForm({ isOpen, onClose, entity }) {
  const user = useUser().state.user;

  const setRoom = useEntity().setRoom;
  const dispatch = useEntity().dispatch;

  const handleMessage = useAlert().handleMessage;

  const [processing, setProcessing] = useState(false);
  const router = useRouter();
  const [errors, setErrors] = useState(errorsInitialState);
  const [newRoom, setNewRoom] = useState({
    ...roomInitialState,
    entity_photo: entity.photo ? entity.photo : '',
    entity_title: entity.title,
    type: router.query.type,
  });
  const [roomPhotoFile, setRoomPhotoFile] = useState(null);

  const hasErrors = Object.values(errors).reduce((all, next) => (next ? !!next : all), false);

  const resetStates = () => {
    setErrors(errorsInitialState);
    setNewRoom({
      ...roomInitialState,
      entity_photo: entity.photo ? entity.photo : '',
      entity_title: entity.title,
      type: router.query.type,
    });
  };

  const validate = (key, value) => {
    let error = '';
    switch (key) {
      case 'title':
        if (value.length < 3) {
          error = 'Minimum length 3 characters';
        }

        if (value.length > 80) {
          error = 'Max length 80 characters';
        }
        break;
      default:
        break;
    }
    setErrors((prev) => ({ ...prev, [key]: error }));
  };

  const setData = (key, value) => {
    if (typeof key === 'string') {
      setNewRoom((prev) => ({ ...prev, [key]: value }));
    } else {
      setNewRoom(key);
    }
  };

  const handleBlur = (key, value) => {
    if (value) {
      validate(key, value);
    }
  };

  const handleFileChange = (file) => {
    const blobUrl = !file.url ? URL.createObjectURL(file) : file.url;

    // helper Image object
    const image = new Image();
    image.src = blobUrl;
    image.onload = () => {
      // have to wait till it's loaded
      const src = resizeMe(image); // send it to canvas
      setData('room_photo', src);
      const source = {
        src,
        file,
      };
      setRoomPhotoFile(source);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const { room_photo, ...uploadRoom } = newRoom;
      let filePath = room_photo;
      if (roomPhotoFile) {
        const { file } = roomPhotoFile;
        const uploadeFilePath = getSlug({ title: newRoom.title, user, file });
        const { publicURL } = await uploadFile({ file, filePath: uploadeFilePath });
        filePath = publicURL;
      }
      const supabase = await getSupabase();
      const { data: room } = await supabase
        .from('rooms')
        .insert([
          {
            ...uploadRoom,
            room_photo: filePath,
            entity_id: entity.id,
            created_by: user.id,
          },
        ])
        .select(
          `*,  user:users_selection_table!created_by(${userDetails}), users:room_users(count), ${allDiscussionsSQL}, messages_count:messages_with_engagements!messages_room_id_fkey(count), discussions_count:discussions!discussions_room_id_fkey(count)`,
        )
        .single();

      if (room) {
        global.analytics.track(
          'Created a Room',
          {
            category: 'Rooms',
          },
          {
            page: {
              path: `/entity${router.query.type}/x/rooms/create`,
              url: `${process.env.NEXT_PUBLIC_DOMAIN}entity${router.query.type}/x/rooms/create`,
            },
            context: {
              ip: '0.0.0.0',
            },
          },
        );
        const { error: relationError } = await supabase
          .from('room_users')
          .insert([{ room_id: room.id, user_id: user.id }]);
        if (!relationError) {
          resetStates();
          setRoom(dispatch, room);
          onClose();
          handleMessage({
            message: { message: 'Room created. Taking you to your room now', type: 'success' },
          });
          router.push(
            `/e/${room.type}/${slugify(room.entity_title)}-${room.entity_id}/${room.slug}`,
          );
        }
      }
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (entity) {
      setData('entity_id', entity.id);
    }
  }, [entity]);

  useEffect(() => {
    if (!isOpen) {
      setErrors(errorsInitialState);
      setNewRoom({
        ...roomInitialState,
        entity_photo: entity.photo ? entity.photo : '',
        entity_title: entity.title,
        type: router.query.type,
      });
    }
  }, [isOpen, router.query.type, entity.photo, entity.title]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!processing) {
          onClose();
        }
      }}
      transparent
    >
      <section className="space-y-4 p-8 bg-white rounded-lg">
        <h1>Create room</h1>
        <form
          onSubmit={handleSubmit}
          name="Create room form"
          method="create"
          className="space-y-10"
        >
          <section className="space-y-4">
            <section className="flex flex-wrap w-full space-y-1">
              <TextInput
                className="w-full"
                inputClasses="border p-2"
                label="Room title"
                name="title"
                required
                longText
                errors={errors.title}
                value={newRoom.title}
                onChange={(e) => setData('title', e.target.value)}
                onBlur={(e) => handleBlur('title', e.target.value)}
                disabled={processing}
              />
              <p className="text-sm text-gray-500">{newRoom.title.length}/80</p>
            </section>
            <section className="flex flex-wrap w-full">
              {newRoom.room_photo && (
                <section className="relative">
                  {processing && <Spinner className="absolute left-2 top-2" />}
                  <IconButton
                    className="absolute right-2 top-2 rounded-full bg-black/40 hover:bg-black"
                    variant="light"
                    onClick={() => {
                      setData('room_photo', '');
                      setRoomPhotoFile(null);
                    }}
                    disabled={processing}
                  >
                    <IoClose className="w-6 h-6" />
                  </IconButton>
                  <img
                    className="w-full h-full max-h-96 object-contain rounded-lg border"
                    src={newRoom.room_photo}
                    layout="fill"
                    alt="Room"
                  />
                </section>
              )}
              {!newRoom.room_photo && (
                <FileInput
                  className="file-input"
                  inputClasses="border p-2"
                  label="Add a photo for your room"
                  name="room_photo"
                  errors={errors.room_photo}
                  value={newRoom.room_photo}
                  onChange={handleFileChange}
                  disabled={processing}
                  accept="image/webp,image/png,image/gif,image/jpeg"
                />
              )}
            </section>
          </section>
          <Button disabled={hasErrors || processing} type="submit">
            Create room
          </Button>
        </form>
      </section>
    </Modal>
  );
}
