export default function RoomSkeleton() {
  return (
    <section className="w-full border shadow rounded-lg space-y-4 animate-pulse">
      <section className="w-full h-44 bg-gray-200" />
      <section className="p-4">
        <section className="w-12 h-12 bg-gray-200 rounded-full -mt-14 mb-4 shadow" />
        <section className="space-y-2">
          <section className="flex items-center justify-between">
            <section className="w-16 h-3 bg-gray-200 rounded" />

            <section className="flex items-center w-5/12 justify-end">
              <section className="w-8 h-8 bg-gray-200 rounded-full mr-4" />
              <section className="w-10/12 h-5 bg-gray-200 rounded" />
            </section>
          </section>
          <section className="flex items-center justify-between">
            <section className="space-y-2 w-6/12">
              <section className="w-2/6 h-4 bg-gray-200 rounded" />
              <section className="w-10/12 h-5 bg-gray-200 rounded" />
            </section>
            <section className="w-20 h-10 bg-gray-200 rounded-lg" />
          </section>
        </section>
      </section>
    </section>
  );
}
