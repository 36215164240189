import merge from 'lodash.merge';

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJson(response) {
  return response.json();
}

export function isJson(response) {
  const contentType = response.headers.get('content-type');
  return response?.status !== 204 && contentType && contentType.indexOf('application/json') !== -1;
}

export default function authFetch(url, opts, { returnRaw = false } = {}) {
  const authOpts = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache',
    },
  };
  return fetch(url, merge(authOpts, opts))
    .then(checkHttpStatus)
    .then((response) => {
      const payload = !returnRaw && isJson(response) ? parseJson(response) : response;
      return payload;
    });
}
