import { useRouter } from 'next/router';

const useRedirectTo = (path) => {
  const router = useRouter();
  const searchParams = new URLSearchParams();
  searchParams.append('redirectTo', path || router.asPath);
  const redirectTo = `${process.env.NEXT_PUBLIC_DOMAIN}?${searchParams.toString()}`;
  return redirectTo;
};

export default useRedirectTo;
