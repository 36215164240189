import { useEffect } from 'react';
import { getSupabase } from '../../../supabase';

export default function useMarkNotificationsRead({ expired, userId, notifications, route }) {
  useEffect(() => {
    if (route === '/notifications' && !expired) {
      (async () => {
        const supabase = await getSupabase();

        const unread = notifications
          .filter((n) => !n.read)
          .map((n) => ({
            id: n.id,
            engager_id: n.engager_id,
            post_id: n.post_id,
            user_id: n.user_id,
            type: n.type,
            read: true,
          }));

        if (unread.length && userId) {
          await supabase.from('notifications').insert(unread, { upsert: true });
        }
      })();
    }
  }, [notifications, route, expired, userId]);
}
