import { Menu as HUIMenu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import Button from '../../Button/Button';

export default function Menu({
  id,
  heading,
  options,
  children,
  className,
  position,
  footer,
  label,
}) {
  const wrapperClasses = classNames(className);
  const menuClasses = classNames({
    'sr-only': !label,
    'pb-1 border-b-2 focus:border-gray-500 hover:border-gray-500 focus:pb-[0.3rem] hover:pb-[0.3rem]':
      label,
  });
  const menuContentClasses = classNames('menu-content', {
    'origin-top-left left-0': position === 'left',
    'origin-top-right right-0': position === 'right',
  });
  return (
    <HUIMenu className="relative inline-block text-left">
      <>
        <HUIMenu.Button className={wrapperClasses} id={id}>
          {children}
          <span className={menuClasses}>{!label ? 'Menu' : label}</span>
        </HUIMenu.Button>
        {options && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <HUIMenu.Items className={menuContentClasses}>
              {heading && heading}
              <div className="container">
                {options.map((option) => (
                  <HUIMenu.Item key={option.key}>
                    {({ active }) => {
                      const menuItemClasses = classNames('menu-item', {
                        active,
                      });

                      return (
                        <Button
                          variant="clear"
                          className={menuItemClasses}
                          href={option.href}
                          as={option.href ? 'a' : ''}
                          onClick={option.onClick}
                        >
                          {option.icon && <option.icon className="icon" />}
                          <p>{option.name}</p>
                        </Button>
                      );
                    }}
                  </HUIMenu.Item>
                ))}
                {footer && footer}
              </div>
            </HUIMenu.Items>
          </Transition>
        )}
      </>
    </HUIMenu>
  );
}
