/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

const TextInput = ({
  label,
  name,
  className,
  longText,
  errors = [],
  validation,
  inputClasses,
  required,
  startButton,
  endButton,
  ...props
}) => {
  const inputWrapper = classNames('form-input', inputClasses, {
    error: errors.length,
  });
  return (
    <div className={className}>
      {startButton && startButton}
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
          {required ? <span className="text-red-500">*</span> : ''}
        </label>
      )}
      {!longText && (
        <input id={name} name={name} required={required} {...props} className={inputWrapper} />
      )}
      {longText && (
        <TextareaAutosize
          id={name}
          name={name}
          required={required}
          {...props}
          className={inputWrapper}
        />
      )}
      {!!errors.length && <div className="form-error">{errors}</div>}
      {validation && <div className="form-success">{validation}</div>}
      {endButton && endButton}
    </div>
  );
};

export default TextInput;
