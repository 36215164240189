const allUsersSQL = 'user:users(id, name, username, photo)';

export const userDetails = 'id, name, username, photo';
const userSQL = `user:user_id(${userDetails})`;

const initialMessageSQL = `likes:message_likes(*), insights:message_insightful(*), freshs:message_fresh(*), ${userSQL}, room:room_id(id, slug, entity_id, title, type)`;

const nestedChildSQL = `children:messages_parent_id_fkey(*, ${initialMessageSQL}`;

const childSQL = `children:messages_parent_id_fkey(*, ${nestedChildSQL}), ${initialMessageSQL})`;

const parentSQL = `parent:parent_id(*, ${childSQL}, ${initialMessageSQL})`;

export const usersMessagesSQL = `messages:messages_user_id_fkey(*, ${parentSQL}, ${childSQL}, ${initialMessageSQL})`;

export const discussionSQL = `*, listeners_count:discussion_listeners(count), listeners:discussion_listeners(user_id, ${allUsersSQL})`;

export const discussionRoomSQL = `room:discussions_room_id_fkey(*, user:users_selection_table!created_by(*), users:room_users(count), messages_count:messages_with_engagements!messages_room_id_fkey(count)), ${discussionSQL}`;

export const allDiscussionsSQL = `discussions!discussions_room_id_fkey(${discussionSQL})`;

export const roomSQL = `*, discussions(${discussionSQL}), messages_countmessages_with_engagements!:messages_room_id_fkey(count), discussions_count:discussions(count), user:users_selection_table!created_by(${userDetails}), users:room_users(count)`;

export const allRoomsSQL = `rooms:room_users(room:rooms(${roomSQL})`;
