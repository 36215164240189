import { FiBell } from 'react-icons/fi';
import { useUser } from '../../../lib/user';
import NotifcationIcon from './NotificationIcon';

export default function Notification(props) {
  const {
    state: { user },
  } = useUser();
  return (
    <>
      <FiBell {...props} />
      {user.id && <NotifcationIcon />}
    </>
  );
}
