import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FiShare2 } from 'react-icons/fi';
import { useAlert } from '../../../../lib/alert';
import { usePage } from '../../../../lib/page';
import BackButton from '../../../BackButton/BackButton';
import Button, { IconButton } from '../../../Button';
import Image from '../../../Image';
import Sekelton from './Sekelton';

function EntityHeader({ title, description, photo, entityType, onCreate, isLoading }) {
  const handleMessage = useAlert().handleMessage;
  const setHideHero = usePage().setHideHero;
  const router = useRouter();
  const { route } = router;
  const { entitySlug, type } = router.query;

  useEffect(() => {
    if (!isLoading) {
      setHideHero(true);
    }
  }, [isLoading, setHideHero]);

  if (isLoading) {
    return <Sekelton />;
  }

  const handleShare = async () => {
    const shareLink = `${window.location.origin}/e/${type}/${entitySlug}`;

    if (navigator.share) {
      await navigator.share({
        title: window.location.hostname,
        text: `Join the best discussions for ${title} on ${process.env.NEXT_PUBLIC_APP_NAME}`,
        url: shareLink,
      });
    } else {
      navigator.clipboard.writeText(shareLink);
      handleMessage({
        message: {
          message: 'Entity link copied!',
          type: 'success',
        },
      });
    }
  };

  return (
    <section className="entity-header">
      <section className="wrapper">
        <section className="hero-content">
          <BackButton variant="light" />
          <section className="flex items-center -mx-4">
            <section className="px-4">
              <section
                className="rounded shadow-lg ring-2 ring-white overflow-hidden relative"
                style={{
                  width: 100,
                  height: 150,
                }}
              >
                <Image className="w-full h-full object-cover" alt={`${title} cover`} src={photo} />
              </section>
            </section>
            <section className="space-y-2 px-4">
              <h1>
                <span className="sr-only" aria-hidden="true">
                  Join a discussion for{' '}
                </span>
                {title}
                <span className="sr-only" aria-hidden="true">
                  {' '}
                  on {process.env.NEXT_PUBLIC_APP_NAME}
                </span>
              </h1>
              <h3 className="text">{entityType}</h3>
              <p className="text">
                <span // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                {route !== '/e/[type]/[entitySlug]/details' && (
                  <Button
                    as="a"
                    href={`/e/${type}/${entitySlug}/details`}
                    variant="clear"
                    className="underline"
                  >
                    see more
                  </Button>
                )}
              </p>
            </section>
          </section>
          <section className="flex items-center space-x-8">
            <Button onClick={onCreate} outlined>
              Create a room
            </Button>
            <IconButton variant="light" className="back-button" onClick={handleShare}>
              <FiShare2 className="w-6 h-6" />
              <span className="sr-only">Share</span>
            </IconButton>
          </section>
        </section>
      </section>
      <div className="overlay" />
      <section className="bg-image">
        <Image
          alt={`${title} cover`}
          className="wrapper"
          src={photo}
          width={1680}
          height={1720}
          layout="responsive"
        />
      </section>
    </section>
  );
}

// EntityHeader.whyDidYouRender = true;

export default EntityHeader;
