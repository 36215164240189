import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { FiInfo } from 'react-icons/fi';
import { checkUserUsername, generateUniqueUsernames } from '../../../lib/supabase/actions';
import { useUser } from '../../../lib/user';
import Button from '../../Button';
import TextInput from '../../FormFields/TextInput';
import Modal from '../Modal/Modal';

const MIN_CHARACTERS = 3;

const isValid = (username) => username.length >= MIN_CHARACTERS;

export default function SetUsernameModal({ isOpen }) {
  const router = useRouter();
  const { route } = router;
  const dispatch = useUser().dispatch;
  const actions = useUser().actions;
  const user = useUser().state.user;
  const options = useUser().state.options;

  const [username, setUsername] = useState('');
  const [isAvailable, setUsenameIsAvailable] = useState(null);
  const [availableUserNames, setAvailableUserNames] = useState([]);
  const [userNameError, setUsernameError] = useState('');

  const label = user.username ? 'Update your username' : 'Claim your username to continue';
  const fieldContainerClasses = classNames('bg-white p-8 rounded-lg shadow-lg space-y-4');

  const hasErrors = !!userNameError || !isValid(username);
  const hasChangedValues = user.username !== username;

  const resetStates = () => {
    setUsenameIsAvailable(null);
    setUsernameError('');
    setAvailableUserNames([]);
  };

  const checkUserName = async () => {
    resetStates();
    if (hasChangedValues) {
      setUsernameError('');
      if (isValid(username)) {
        const { available } = await checkUserUsername({ username });
        if (!available && username.toLocaleLowerCase() !== user.username.toLocaleLowerCase()) {
          const userNames = await generateUniqueUsernames({ username });
          setAvailableUserNames(userNames);
          const errorMessage = !userNames.length ? 'No usernames available' : 'Username taken';
          setUsernameError(errorMessage);
        }
        setUsenameIsAvailable(available);
      } else {
        setUsernameError(`Username must be longer than ${MIN_CHARACTERS} characters`);
      }
    }
  };

  const handleChange = (e) => {
    resetStates();

    const value = e.target.value.trim();

    setUsername(value);
  };

  const handleClose = () => {
    setUsername(user.username || '');
    resetStates();
    return actions.requestUpdateUser(dispatch, false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasChangedValues) {
      actions.updateUser(dispatch, { id: user.id, username });
      global.analytics.track(
        'Updated Username',
        {
          category: 'Profiles',
        },
        {
          page: {
            path: `/user-details/username`,
            url: `${process.env.NEXT_PUBLIC_DOMAIN}user-details/username`,
          },
          context: {
            ip: '0.0.0.0',
          },
        },
      );
      if (options.callback) {
        options.callback();
      }
      if (route === '/[username]') {
        return router.replace(`/${username}`);
      }
    }
    return handleClose();
  };

  useEffect(() => {
    if (user.username) {
      setUsername(user.username);
    }
  }, [user.username]);

  return (
    <Modal isOpen={isOpen} transparent onClose={handleClose}>
      <form className={fieldContainerClasses} onSubmit={handleSubmit}>
        <section>
          <TextInput
            className="w-full"
            inputClasses="border p-2"
            label={label}
            name="username"
            spellCheck="false"
            autoFocus
            required
            errors={userNameError}
            value={username}
            onChange={handleChange}
            onBlur={checkUserName}
          />
          {!!availableUserNames.length && (
            <p>
              Try one of these usernames:{' '}
              {availableUserNames.map((un, i) => (
                <>
                  <Button
                    variant="clear"
                    className="text-blue-500"
                    onClick={() => {
                      setUsername(un.username);
                      setUsernameError('');
                    }}
                  >
                    {un.username}
                  </Button>
                  {availableUserNames.length > 1 && i < availableUserNames.length && ', '}
                </>
              ))}
            </p>
          )}
          {!hasErrors &&
            isAvailable &&
            user.username &&
            !username.toLocaleLowerCase() !== user.username.toLocaleLowerCase() &&
            hasChangedValues && (
              <p className="flex items-center text-green-500">
                Username available <BiCheckCircle className="ml-2" />
              </p>
            )}
          {user.id &&
            user.username &&
            !username.toLocaleLowerCase() !== user.username.toLocaleLowerCase() &&
            hasChangedValues && (
              <section className="bg-blue-100 border border-blue-300 p-2 mt-4 rounded-lg flex items-center space-x-4 text-blue-800">
                <FiInfo className="w-6 h-6" />
                <p className="flex items-center">
                  You will be redirected to your new profile page after updating
                </p>
              </section>
            )}
        </section>
        <Button disabled={hasErrors} type="submit">
          {user.username ? 'Update your' : 'Claim'} username
        </Button>
      </form>
    </Modal>
  );
}
