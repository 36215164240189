import Alert from './Alert';

export const AlertList = ({ messages }) => (
  <div className="alerts">
    <section className="alert-wrapper">
      {messages.map((message) => (
        <Alert
          key={message.message}
          type={message.type}
          message={message.message}
          action={message.action}
          dismiss={message.dismiss}
        />
      ))}
    </section>
  </div>
);

export default AlertList;
