import { format } from 'date-fns';
import truncateString from '../../../utils/truncateString';
import { allRoomsSQL } from '../../sql';
import { getSupabase } from '../../supabase';

export const getUser = async ({ userId, select = '*' }) => {
  const supabase = await getSupabase();

  try {
    const { data: user } = await supabase.from('users').select(select).eq('id', userId).single();

    return { user };
  } catch (error) {
    return { error, user: {} };
  }
};

export const getUsersLike = async ({ query, select }) => {
  const supabase = await getSupabase();

  try {
    const { data } = await supabase
      .from('users')
      .select(select)
      .ilike('username, name', `%${query}%`);

    const users = data.map((user) => ({
      id: user.id,
      title: `@${user.username}`,
      username: user.username,
      photo: user.photo,
      description: truncateString(user.bio),
      user_details: {
        rooms: user.rooms.filter((_, i) => i < 6).map(({ room }) => ({ name: room.title })),
      },
    }));

    return { users };
  } catch (error) {
    return { error, users: [] };
  }
};

export const getUserByUsername = async ({ username }) => {
  const supabase = await getSupabase();

  try {
    const { data: users } = await supabase
      .from('users')
      .select('username')
      .ilike('username', `%${username}%`);

    return { users };
  } catch (error) {
    return { error, users: [] };
  }
};

export const checkUserUsername = async ({ username }) => {
  const supabase = await getSupabase();

  try {
    const { data: users } = await supabase
      .from('users')
      .select('username')
      .ilike('username', username);

    if (users.length) {
      return {
        available: false,
        error: null,
      };
    }

    return {
      username,
      available: true,
      error: null,
    };
  } catch (error) {
    return { error, username: null, available: false };
  }
};

export const generateUniqueUsernames = async ({ username }) => {
  const userNamesList = [];
  const firstChar = username.slice(0, 1);
  const firstTwoChar = username.slice(0, 2);
  /**
   * an array of numbers that may be used as suffix for the user names index 0 would be the year
   * and index 1, 2 and 3 would be month, day and hour respectively.
   */
  const numSufix = format(new Date(), 'Y-m-d-H').split('-');

  // create an array of nice possible user names
  userNamesList.push(
    username,
    `${firstChar}.${username}`,
    `${firstTwoChar}.${username}`,
    `${username}.${numSufix[0]}`,
    `${username}.${numSufix[1]}`,
    `${username}.${numSufix[2]}`,
    `${username}.${numSufix[3]}`,
  );

  const isAvailable = false; // initialize available with false
  let index;
  const maxIndex = userNamesList.length - 1;
  let availableUserName;
  let limit;
  const calls = [];
  // loop through all the userNameList and find the one that is available
  for (index = 0; index < maxIndex || isAvailable; ) {
    availableUserName = userNamesList[index];
    calls.push(checkUserUsername({ username: availableUserName }));
    limit = index >= maxIndex;
    index += 1;

    if (limit) {
      break;
    }
  }
  const availableUserNames = await (await Promise.all(calls)).filter(({ available }) => available);

  return availableUserNames;
};

export const getUserLike = async ({ username, select = `*, rooms:room_users(${allRoomsSQL})` }) => {
  const supabase = await getSupabase();

  try {
    const { data: user, error } = await supabase
      .from('users')
      .select(select)
      .ilike('username', username)
      .eq('rooms.discussions.ended', false)
      .single();

    return { user, error };
  } catch (error) {
    return { error, user: {} };
  }
};

export const getUserProfile = async ({ username }) => {
  const supabase = await getSupabase();

  try {
    const { data: user, error } = await supabase
      .from('users')
      .select(
        `*, usersRooms:room_users(rooms(*, user:users_selection_table!rooms_created_by_fkey(*), users:room_users(count)))`,
      )
      .ilike('username', username)
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { user, error };
  } catch (error) {
    return { error, user: {} };
  }
};

export const getUsers = async ({ select = '*' }) => {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase.from('users').select(select);
    const users = data || [];
    return { users, error };
  } catch (error) {
    return { error, users: [] };
  }
};
