import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import DiscussionModal from '../../components/Modals/DiscussionModal';
import usePlayerSetup from '../hooks/usePlayerSetup';
import { usePage } from '../page';
import { getSupabase, PER_PAGE } from '../supabase';
import { deleteRoom, getEntityRooms, getRooms } from '../supabase/actions';
import { useUser } from '../user';
import authFetch from '../utils/authFetch';
import getIdFromSlug from '../utils/getIdFromSlug';
import actionTypes from './actionTypes';
import EntityContext from './EntityContext';
import entityReducer, { initialState } from './entityReducer';
import useDiscussionListeners from './hooks/useDiscussionListeners';
import useFetchEntity from './hooks/useFetchEntity';

const ActiveStream = dynamic(() => import('../../components/ActiveStream/ActiveStream'), {
  ssr: false,
});

const EntityProvider = ({ children }) => {
  const router = useRouter();
  const isDiscussionPage = router.route !== '/d/[discussionSlug]';

  const { entitySlug, roomSlug, discussionSlug, type } = router.query;

  const navHeight = usePage().navHeight;
  const roomId = getIdFromSlug(roomSlug);
  const discussionId = isDiscussionPage ? getIdFromSlug(discussionSlug) : '';
  const [state, dispatch] = useImmerReducer(entityReducer, initialState());

  const user = useUser().state.user;
  const requestUpdateName = useUser().actions.requestUpdateName;

  const [hasMessages, setHasMessages] = useState(false);
  const [pageEntity, setPageEntity] = useState({});
  const [currentProfileId, setCurrentProfileId] = useState({});
  const [activeDiscussionId, setActiveDiscussionId] = useState(null);

  const value = useMemo(() => ({ state, dispatch }), [dispatch, state]);

  const entityState = value.state;
  const entityDispatch = value.dispatch;

  const entityId = pageEntity.id ? `${pageEntity.id}` : getIdFromSlug(entitySlug);

  const entities = entityState.entities.ids.map((id) => entityState.entities.byId[id]);

  const getRoomsFromState = () => {
    if (entityId) {
      const roomsState = entityState.entityRooms[entityId]?.rooms ?? {
        ids: [],
        byId: {},
      };
      const allEntityRooms = roomsState.ids.map((rId) => roomsState.byId[rId]);
      return allEntityRooms;
    }
    return entityState.entities.ids.reduce((all, id) => {
      const roomsState = entityState.entityRooms[id]?.rooms ?? {
        ids: [],
        byId: {},
      };
      const allEntityRooms = roomsState.ids.map((rId) => roomsState.byId[rId]);
      return all.concat(allEntityRooms);
    }, []);
  };

  const rooms = getRoomsFromState().sort(
    (a, b) => new Date(b.inserted_at) - new Date(a.inserted_at),
  );

  const usersRooms = rooms.reduce((all, r) => {
    if (r.created_by === currentProfileId) {
      all.push(r);
    }
    return all;
  }, []);

  const getDiscussionsFromState = (eId) => {
    const id = eId ?? entityId;
    if (id && !activeDiscussionId) {
      const roomsState = entityState.entityRooms[id]?.rooms ?? {
        ids: [],
        byId: {},
      };
      const allEntityRooms = roomsState.ids.map((rId) => roomsState.byId[rId]);
      return allEntityRooms.reduce((all, next) => {
        all.push(...next.discussions.map((d) => ({ ...d, room: next })));
        return all;
      }, []);
    }
    return rooms.reduce((all, next) => {
      all.push(...next.discussions.map((d) => ({ ...d, room: next })));
      return all;
    }, []);
  };

  let discussions = getDiscussionsFromState();

  const getRoomFromState = (id) => {
    if (id) {
      return rooms.find((r) => r.id === id);
    }
    if (discussionId) {
      const foundDiscussion = discussions.find((d) => `${d.id}` === discussionId);
      if (foundDiscussion) {
        const discussionRoom = rooms.find((r) => r.id === foundDiscussion.room_id);
        return discussionRoom;
      }
    }
    return entityState.entityRooms[entityId]?.rooms.byId[roomId];
  };

  const room = getRoomFromState() ?? {};

  const getDiscussionFromState = () => {
    if (activeDiscussionId) {
      const foundDiscussion = discussions.find((d) => d.id === activeDiscussionId);
      if (foundDiscussion) {
        return foundDiscussion;
      }
    }
    if (room.id) {
      return room.discussions?.[0] ?? {};
    }
    return {};
  };

  const discussion = getDiscussionFromState();
  discussions = getDiscussionsFromState(room.entity_id);

  discussions = getDiscussionsFromState(discussion.room?.entity_id)
    .filter((d) => !d.ended)
    .sort((a, b) => b.id - a.id);

  const getEntityFromState = () => {
    // const dId = discussionId || discussion.id;
    if (room.id) {
      const foundEntity = entityState.entities.byId[room.entity_id];
      return foundEntity;
    }
    if (pageEntity.id) {
      return pageEntity;
    }
    return entityState.entities.byId[entityId];
  };

  const entity = getEntityFromState() ?? {};

  useFetchEntity({
    entityId,
    type,
    actions: {
      request: () => dispatch({ type: actionTypes.REQUEST_ENTITY }),
      fetchEntity: () => fetchEntity(dispatch, { entityId, type }),
      addEntity: (response) => addEntity(dispatch, response),
      fetchRooms: (id) => fetchRooms(dispatch, { entityId: id }),
      finished: () => dispatch({ type: actionTypes.REQUEST_FINISHED }),
    },
  });

  // Load initial data and set up listeners
  useDiscussionListeners({
    ids: Object.keys(entityState.entityRooms),
    byId: entityState.entityRooms,
    actions: {
      addEntity: (newDiscussion) =>
        entityDispatch({
          type: actionTypes.REQUEST_DISCUSSION_SUCCESS,
          discussion: newDiscussion.discussion,
        }),
      removeEntity: (newEntity) =>
        entityDispatch({
          type: actionTypes.REMOVE_DISCUSSION,
          roomId: newEntity.roomId,
          entityId: newEntity.entityId,
          discussion: newEntity.discussion,
        }),
    },
  });

  const [loadDiscussion, setLoadDiscussion] = useState(false);

  const getActiveDiscussion = useCallback(() => {
    if (activeDiscussionId === discussion.id) {
      return discussion;
    }
    if (activeDiscussionId) {
      return { id: activeDiscussionId, room: {} };
    }
    return {
      room: {},
    };
  }, [activeDiscussionId, discussion]);
  const activeDiscussion = getActiveDiscussion();

  usePlayerSetup({
    activeDiscussionId,
    setActiveDiscussionId,
    user,
    updateName: requestUpdateName,
    discussion,
    loadDiscussion,
  });

  return (
    <EntityContext.Provider
      value={{
        dispatch: entityDispatch,
        state: entityState,
        setRoom,
        pageEntity,
        setPageEntity,
        addRooms,
        removeRoom,
        fetchRooms,
        addEntity,
        rooms,
        room,
        usersRooms,
        currentProfileId,
        setCurrentProfileId,
        discussions,
        discussion,
        entity,
        entities,
        hasMessages,
        setHasMessages,
        fetchTrendingEntities,
        activeDiscussionId,
        setActiveDiscussionId,
        likeDiscussion,
        insightDiscussion,
        freshDiscussion,
      }}
    >
      {children}
      {discussion.id && isDiscussionPage && (
        <DiscussionModal discussion={{ ...discussion, room }} onConfirm={setLoadDiscussion} />
      )}

      {activeDiscussion.id && (
        <section className="discussion" style={{ bottom: navHeight }}>
          <section className="room-stream">
            <ActiveStream
              discussion={activeDiscussion}
              isHost={activeDiscussion.room?.created_by === user.id}
            />
          </section>
        </section>
      )}
    </EntityContext.Provider>
  );
};

EntityProvider.whyDidYouRender = true;
export default EntityProvider;

/**
 * Add rooms to state
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const addRooms = async (dispatch, rooms) => {
  dispatch({ type: actionTypes.REQUEST_ROOMS_SUCCESS, rooms });
  return rooms.map((room) => room.id);
};

/**
 * Add entity to state
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const addEntity = async (dispatch, entity) => {
  dispatch({ type: actionTypes.REQUEST_ENTITY_SUCCESS, entity });
  return entity;
};

/**
 * Fetch entity
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchEntity = async (dispatch, { entityId, type }) => {
  try {
    if (['undefined', '[type]'].includes(type) || ['undefined', '[entityId]'].includes(entityId)) {
      throw new Error('entityId or type must be provided');
    }
    const entity = await authFetch(`${process.env.NEXT_PUBLIC_API}/entity/${type}/${entityId}`);
    return addEntity(dispatch, entity);
  } catch (error) {
    console.error('error', error);
    return { error };
  }
};

/**
 * Fetch trending entities
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchTrendingEntities = async () => {
  try {
    const entities = await authFetch(`${process.env.NEXT_PUBLIC_API}/trending`);
    return { entities, error: null };
  } catch (error) {
    console.error('error', error);
    return { error, entities: [] };
  }
};

/**
 * Fetch rooms for entity
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 */
export const fetchRooms = async (
  dispatch,
  { entityId, range = { rangeFrom: 0, rangeTo: PER_PAGE } },
) => {
  try {
    let rooms = [];
    const select = `*, users:room_users(count), messages_count:messages_with_engagements!messages_room_id_fkey(count), discussions_count:discussions!discussions_room_id_fkey(count)`;
    if (entityId) {
      const { rooms: body } = await getEntityRooms({
        select,
        entityId,
        rangeFrom: range.rangeFrom,
        rangeTo: range.rangeTo,
        eq: {
          param: 'discussions.ended',
          value: false,
        },
      });
      rooms = body;
    } else {
      const { rooms: body } = await getRooms({
        select,
        rangeFrom: range.rangeFrom,
        rangeTo: range.rangeTo,
        eq: {
          param: 'discussions.ended',
          value: false,
        },
      });
      rooms = body;
    }
    if (rooms.length < PER_PAGE) {
      dispatch({ type: actionTypes.SET_ENTITY_CAN_FETCH, canFetchMore: false });
    } else {
      dispatch({ type: actionTypes.SET_ENTITY_CAN_FETCH, canFetchMore: true });
    }

    const roomIds = await addRooms(dispatch, rooms);
    return roomIds;
  } catch (error) {
    console.error('error', error);
    return error;
  }
};

/**
 * Set room in state
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 * @param {object} room The room
 */
export const setRoom = async (dispatch, room) => {
  dispatch({ type: actionTypes.SET_ROOM, room });
};

/**
 * Remove room from state
 * @param {function} dispatch Optionally pass in a hook or callback to set the state
 * @param {object} room The room id
 */
export const removeRoom = async (dispatch, room) => {
  try {
    const response = await deleteRoom(room.id);
    if (dispatch) {
      dispatch({ type: actionTypes.REMOVE_ROOM, room });
    }
    return {
      room: response.room,
      error: null,
    };
  } catch (error) {
    console.error('error', error);
    return { error, room: {} };
  }
};

/**
 * Insert a new liked discussion into the DB
 * @param {object} discussion The discussion
 * @param {number} user_id The user id
 * @param {object} like The like object
 */
export const likeDiscussion = async ({ discussion, user_id, like }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (like?.discussion_id) {
      const response = await supabase
        .from('discussion_likes')
        .update({ marked: !like.marked })
        .match({ discussion_id: discussion.id, room_id: discussion.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('discussion_likes')
        .upsert([
          { discussion_id: discussion.id, room_id: discussion.room_id, user_id, marked: true },
        ])
        .select('*')
        .single();
      data = response.data;
    }
    return { data };
  } catch (error) {
    console.log('error', error);
    return { error };
  }
};

/**
 * Insert a new insight discussion into the DB
 * @param {object} discussion The discussion
 * @param {number} user_id The user id
 * @param {object} insight The insight object
 */
export const insightDiscussion = async ({ discussion, user_id, insight }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (insight?.discussion_id) {
      const response = await supabase
        .from('discussion_insightful')
        .update({ marked: !insight.marked })
        .match({ discussion_id: discussion.id, room_id: discussion.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('discussion_insightful')
        .upsert([
          { discussion_id: discussion.id, room_id: discussion.room_id, user_id, marked: true },
        ])
        .select('*')
        .single();
      data = response.data;
    }
    return { data };
  } catch (error) {
    console.log('error', error);
    return { error };
  }
};
/**
 * Insert a new fresh discussion into the DB
 * @param {object} discussion The discussion
 * @param {number} user_id The user id
 * @param {object} fresh The fresh object
 */
export const freshDiscussion = async ({ discussion, user_id, fresh }) => {
  try {
    const supabase = await getSupabase();

    let data;
    if (fresh?.discussion_id) {
      const response = await supabase
        .from('discussion_fresh')
        .update({ marked: !fresh.marked })
        .match({ discussion_id: discussion.id, room_id: discussion.room_id, user_id })
        .select('*')
        .single();
      data = response.data;
    } else {
      const response = await supabase
        .from('discussion_fresh')
        .upsert([
          { discussion_id: discussion.id, room_id: discussion.room_id, user_id, marked: true },
        ])
        .select('*')
        .single();
      data = response.data;
    }
    return { data };
  } catch (error) {
    console.log('error', error);
    return { error };
  }
};
