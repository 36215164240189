import parseISO from 'date-fns/parseISO';
import humanizeDuration from 'humanize-duration';
import numeral from 'numeral';

const oneHour = 3.6e6;
const halfHour = 1.8e6;
const fiveHundredHours = 1.8e9;
const oneDay = 86.4e6;
const halfDay = 43.2e6;

function formatMins(ms) {
  return humanizeDuration(ms, { largest: 1, round: true, units: ['m', 's'] });
}

function formatHour(ms, includeDecimal) {
  const divisor = includeDecimal ? halfHour : oneHour;
  const remainder = ms % divisor;
  const rounded = ms - remainder;
  return humanizeDuration(rounded, { largest: 1, round: false, units: ['h'] });
}

function formatDays(ms, includeDecimal) {
  const divisor = includeDecimal ? halfDay : oneDay;
  const remainder = ms % divisor;
  const rounded = ms - remainder;
  return humanizeDuration(rounded, { largest: 1, round: false, units: ['d'] });
}

function formatLongTime(ms, opts) {
  if (ms < 1000) {
    return '-';
  }
  if (ms < oneHour) {
    return formatMins(ms);
  }
  if (ms < fiveHundredHours) {
    return formatHour(ms, opts.decimal);
  }
  return formatDays(ms, opts.decimal);
}

function formatShortTime(ms, opts) {
  let round = true;
  if (ms < 60000 && opts.decimal) {
    round = false;
  }
  return humanizeDuration(ms, {
    spacer: '',
    largest: 2,
    round,
    delimiter: '',
    language: 'short',
    languages: {
      short: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
        decimal: () => '.',
      },
    },
  });
}

export function formatTime(ms, opts = { shorten: false, decimal: false }) {
  if (opts.shorten) {
    return formatShortTime(ms, opts);
  }
  return formatLongTime(ms, opts);
}

export function formatNumber(num, opts = { shorten: false, decimal: false }) {
  if (opts.shorten && num >= 1e3) {
    return numeral(num).format('0.[0]a');
  }
  if (opts.decimal) {
    return numeral(num).format('0.0');
  }
  return numeral(num).format();
}

export function formatNumberShort(num) {
  return formatNumber(num, { shorten: true });
}

export function formatPercentage(perc) {
  const rounded = Math.floor(perc * 100) / 100;
  return numeral(rounded).format('%');
}

const units = ['B', 'KB', 'MB', 'GB'];

export function formatBytes(bytes) {
  let bytesRemaining = bytes;
  let unitIndex = 0;

  while (bytesRemaining >= 1024 && unitIndex < 3) {
    bytesRemaining /= 1024;
    unitIndex += 1;
  }

  return `${formatNumber(bytesRemaining)}${units[unitIndex]}`;
}

export const timeUnits = {
  MINUTES: 'minutes',
  HOURS: 'hours',
};

export function formatTimeHHMMSS(ms, opts = { defaultLargestUnit: timeUnits.MINUTES }) {
  // limit value to between 00:00 and 23:59:59
  const time = Math.max(Math.min(ms, oneHour * 24 - 1000), 0);

  let startSubstring = 14;
  let substringLength = 5;
  if (opts.defaultLargestUnit === timeUnits.HOURS || time >= oneHour) {
    startSubstring = 11;
    substringLength = 8;
  }
  return new Date(time).toISOString().substr(startSubstring, substringLength);
}

export function parseUTC(s) {
  return parseISO(s);
}
