import { formatDistanceToNowStrict } from 'date-fns';
import dynamic from 'next/dynamic';
import formatDistance from '../../../lib/utils/formatDistance';
import DiscussionReaction from '../../ActiveStream/MiniPlayer/EndedPlayer/DiscussionReaction';
import Button from '../../Button';
import { Logo } from '../../Icons';
import Image from '../../Image';
import Card from '../Card/Card';

const AudioPlayer = dynamic(() => import('../../AudioPlayer'), {
  ssr: false,
});

export default function StreamCard({ discussion, onLogin, room }) {
  const timeStamp =
    discussion.inserted_at &&
    formatDistanceToNowStrict(new Date(discussion.inserted_at), {
      locale: { formatDistance },
    });
  return (
    <Button as="a" variant="clear" href={`/d/${discussion.slug}`}>
      <Card>
        <AudioPlayer
          title={
            <section className="flex justify-between w-full">
              <section className="flex space-x-4 item-center">
                <section className="min-w-[2rem] w-8 h-10 relative border-2 rounded-sm overflow-hidden">
                  <Image
                    className="bg-gray-300 w-full h-full object-cover"
                    src={room.entity_photo}
                    alt={`${room.entity_title} main`}
                  />
                </section>
                <section>
                  <p className="text-gray-700 text-sm">
                    <Logo className="w-3 inline-block" hideTag /> <span>Discussed</span>
                  </p>
                  <p>{discussion.title}</p>
                </section>
              </section>
              <p className="text-sm">{timeStamp}</p>
            </section>
          }
          audio={discussion.stream_link}
        />
        <section className="border-t pt-2">
          <DiscussionReaction discussion={discussion} onLogin={onLogin} altLabels />
        </section>
      </Card>
    </Button>
  );
}
