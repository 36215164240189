import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNotifications } from '../../../../lib/notifications';

export default function NotifcationIcon() {
  const { unreadNotifications } = useNotifications();
  return (
    <Transition
      as={Fragment}
      show={!!unreadNotifications.length}
      enter="transform transition ease-out duration-400"
      enterFrom="transform opactiy-0 rotate-[-120deg] scale-50 absolute -top-0 -right-0"
      enterTo="transform opactiy-100 rotate-0 scale-100 absolute -top-0 -right-0"
      leave="transform duration-200 transition ease-in-out absolute -top-0 -right-0"
      leaveFrom="opacity-100 rotate-0 scale-100 absolute -top-0 -right-0"
      leaveTo="opacity-0 scale-95 absolute -top-0 -right-0"
    >
      <section className="nav-notification">{unreadNotifications.length}</section>
    </Transition>
  );
}
