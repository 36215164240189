import { useEffect } from 'react';
import { getSupabase, userDetails } from '../../../supabase';

export default function useGetNotifications({ userId, actions }) {
  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          const supabase = await getSupabase();

          const { data: messageNotifications, error: messageError } = await supabase
            .from('notifications')
            .select(
              `*, post:messages_with_engagements!notifications_post_id_fkey(message, parent_id), message:messages_with_engagements!notifications_message_id_fkey(*), user:users_selection_table!engager_id(${userDetails})`,
            )
            .eq('user_id', userId)
            .in('type', ['like', 'fresh', 'insight', 'message'])
            .order('inserted_at', { ascending: false });
          if (messageError) {
            console.error('🥵 ~ error', messageError);
          }
          const { data: discussionNotifications, error } = await supabase
            .from('notifications')
            .select(`*, user:users_selection_table!engager_id(${userDetails})`)
            .eq('user_id', userId)
            .in('type', ['discussion_like', 'discussion_fresh', 'discussion_insight'])
            .order('inserted_at', { ascending: false });
          if (error) {
            console.error('🥵 ~ error', error);
          }
          const notifications = (messageNotifications || [])
            .reduce((a, n) => {
              if (n.message_id && !n.message) {
                return a;
              }
              a.push(n);
              return a;
            }, [])
            .concat(discussionNotifications || [])
            .sort((a, b) => new Date(b.inserted_at) - new Date(a.inserted_at));

          if (notifications) {
            notifications.forEach((n) => actions.setUser(n.user));
            actions.setNotifications(notifications);
          }
        } catch (error) {
          console.error('🥵 ~ error', error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
}
