import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getRoomDiscussions } from '../../../lib/supabase/actions';
import { CardSkeleton } from '../../Cards/Card';
import StreamCard from '../../Cards/StreamCard';
import Modal from '../Modal';

export default function ViewRoomDiscussionsModal({ isOpen, room, onClose, onLogin }) {
  const [discussions, setDiscussions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const wrapperClasses = classNames('p-4 bg-white rounded-lg shadow-lg space-y-4');
  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          const data = await getRoomDiscussions({ roomId: room.id });
          if (data.discussions) {
            const roomDiscussions = data.discussions.sort(
              (a, b) => new Date(b.inserted_at) - new Date(a.inserted_at),
            );
            setDiscussions(roomDiscussions);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [room.id, isOpen]);

  return (
    <Modal isOpen={isOpen} transparent onClose={onClose}>
      <section className="pt-16">
        <section className={wrapperClasses}>
          <section className="border-b pb-2">
            <h1 className="font-bold line-clamp-2">{room.title} past live discussions</h1>
          </section>
          {isLoading && [...Array(5).keys()].map((i) => <CardSkeleton key={i} />)}
          {!isLoading && (
            <section className="p-4 border rounded-lg bg-gray-50 space-y-4 overflow-y-scroll max-h-[50vh]">
              {!discussions.length && (
                <section className="text-center p-4 space-y-4">
                  <h2 className="text-xl text-gray-400">
                    💭
                    <br />
                    No past discussions for <strong>{room.title}</strong>
                  </h2>
                </section>
              )}
              {discussions.map((discussion) => (
                <StreamCard
                  key={discussion.id}
                  discussion={discussion}
                  onLogin={onLogin}
                  room={room}
                />
              ))}
            </section>
          )}
        </section>
      </section>
    </Modal>
  );
}
