import classNames from 'classnames';
import Button from './Button';

export default function IconButton({
  onClick,
  children,
  className,
  ariaHidden,
  variant,
  as,
  href,
  target,
  rel,
  download,
  ...rest
}) {
  const classes = classNames('btn-icon ', className, {
    'btn-light': variant === 'light',
    'icon-focused': variant !== 'light',
  });
  return (
    <Button
      className={classes}
      as={as}
      href={href}
      target={target}
      rel={rel}
      onClick={onClick}
      ariaHidden={ariaHidden}
      download={download}
      variant="clear"
      {...rest}
    >
      {children}
    </Button>
  );
}
