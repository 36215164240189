import { useEffect } from 'react';

export default function usePageAnalytics({ analytics, route, query }) {
  useEffect(() => {
    if (analytics) {
      const searchParams = new URLSearchParams();
      if (route === '/e/[type]') {
        Object.entries(query).forEach(([key, value]) => {
          searchParams.append(key, value);
        });
      }
      const { name, category, url, path, ...restAnalytics } = analytics;
      global.analytics.page(
        category,
        name,
        {
          ...restAnalytics,
          url,
          path,
          search: searchParams.toString(),
        },
        {
          context: {
            ...restAnalytics.context,
            ip: '0.0.0.0',
            page: {
              url,
              path,
            },
          },
        },
      );
    }
  }, [analytics, query, route]);
}
