import { MessagesProvider } from '../../../lib/messages';
import EntityAside from '../../Entities/Entity/EntityAside';
import { getLayout as getAppLayout } from '../AppLayout';

const MessagesLayout = ({ children }) => {
  return (
    <MessagesProvider>
      {children}
      <aside className="sidebar">
        <EntityAside />
      </aside>
    </MessagesProvider>
  );
};

export const getLayout = (page) => getAppLayout(<MessagesLayout>{page}</MessagesLayout>);

export default MessagesLayout;
