import slugify from '../utils/slugify';
import actionTypes from './actionTypes';

const newState = () => ({
  ids: [],
  byId: {},
  canFetchMore: true,
});

export const initialState = () => ({
  isLoading: true,
  entities: newState(),
  entityRooms: {},
});

const entityReducer = (draft, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_ENTITY: {
      draft.isLoading = true;
      break;
    }
    case actionTypes.REQUEST_FINISHED: {
      draft.isLoading = false;
      break;
    }
    case actionTypes.SET_ENTITY_CAN_FETCH: {
      const { canFetchMore } = action;
      draft.canFetchMore = canFetchMore;
      break;
    }
    case actionTypes.REQUEST_ENTITY_SUCCESS: {
      const { entity } = action;
      const slug = slugify(entity.title);
      const entity_slug = `${slug}-${entity.id}`;
      if (!draft.entities.byId[entity.id]) {
        draft.entities.byId[entity.id] = { ...entity, slug: entity_slug };
        draft.entities.ids.push(entity.id);

        if (!draft.entityRooms[entity.id]) {
          draft.entityRooms[entity.id] = { rooms: newState() };
        }
      } else {
        const rooms = draft.entityRooms[entity.id].rooms ?? newState();
        draft.entities.byId[entity.id] = { ...entity, slug: entity_slug };
        draft.entityRooms[entity.id] = { rooms };
      }
      break;
    }
    case actionTypes.SET_ROOM: {
      const { room } = action;

      const entity = draft.entityRooms[room.entity_id];

      if (!entity.rooms.byId[room.id]) {
        entity.rooms.ids.unshift(room.id);
      }

      entity.rooms.byId[room.id] = { ...room, entity };

      draft.entityRooms[room.entity_id] = entity;

      break;
    }
    case actionTypes.REMOVE_ROOM: {
      const { room } = action;
      const entity = draft.entityRooms[room.entity_id];

      const { [room.id]: _, ...byId } = entity.rooms.byId;
      entity.rooms = {
        byId,
        ids: entity.rooms.ids.filter((id) => id !== room.id),
      };

      draft.entityRooms[room.entity_id] = entity;

      break;
    }
    case actionTypes.REQUEST_ROOMS_SUCCESS: {
      const { rooms: newRooms } = action;

      newRooms.forEach((room) => {
        const entity = draft.entityRooms[room.entity_id] ?? {
          rooms: newState(),
        };
        const rooms = entity.rooms;
        rooms.ids = Array.from(new Set([room.id, ...rooms.ids]));
        rooms.byId[room.id] = room;
        rooms.isLoading = false;
        entity.rooms = rooms;
        draft.entityRooms[room.entity_id] = entity;
      });

      draft.entities = newRooms.reduce((all, next) => {
        if (!all.byId[next.entity_id]) {
          all.ids.push(next.entity_id);
        }

        const slug = slugify(next.entity_title);
        const entity_slug = `${slug}-${next.entity_id}`;
        const entity = all.byId[next.entity_id] ?? {
          id: next.entity_id,
          title: next.entity_title,
          photo: next.entity_photo,
          e_type: next.type,
          slug: entity_slug,
        };
        all.byId[next.entity_id] = entity;
        return all;
      }, draft.entities);

      break;
    }
    case actionTypes.REQUEST_DISCUSSION_SUCCESS: {
      const { discussion } = action;
      const entity = draft.entityRooms[discussion.room.entity_id] || { rooms: newState() };
      const room = entity.rooms.byId[discussion.room_id] ?? {
        id: discussion.room.entity_id,
        title: discussion.room.entity_title,
        photo: discussion.room.entity_photo,
        e_type: discussion.room.type,
        slug: `${slugify(discussion.room.entity_title)}-${discussion.room.entity_id}`,
      };

      room.discussions = [discussion];
      entity.rooms.byId[discussion.room_id] = room;
      draft.entityRooms[discussion.room.entity_id] = entity;

      break;
    }
    case actionTypes.REMOVE_DISCUSSION: {
      const { roomId, entityId, discussion } = action;
      const entity = draft.entityRooms[entityId];
      const room = entity.rooms.byId[roomId];

      const entityDiscussion = room.discussions.find((d) => d.id === discussion.id);

      room.discussions = room.discussions.filter((d) => d.id !== discussion.id);
      room.discussions.push({ ...entityDiscussion, ...discussion });
      entity.rooms.byId[roomId] = room;
      draft.entityRooms[entityId] = entity;

      break;
    }
    default: {
      throw Error(`Uncaught action of type ${action.type}`);
    }
  }
};

export default entityReducer;
