import { useEffect } from 'react';
import { createDiscussionListeners } from '../supabase/actions';

export default function usePlayerSetup({
  activeDiscussionId,
  setActiveDiscussionId,
  user,
  updateName,
  discussion,
  loadDiscussion,
}) {
  useEffect(() => {
    if (activeDiscussionId === null && discussion.id && !discussion.ended && loadDiscussion) {
      setActiveDiscussionId(discussion.id);
      const foundListener = discussion.listeners.find((dI) => dI.user.id === user.id);
      if (!foundListener && user.id) {
        (async () => {
          await createDiscussionListeners({
            insert: [{ user_id: user.id, discussion_id: discussion.id }],
          });
        })();
      }
    }
  }, [
    activeDiscussionId,
    discussion.id,
    discussion.listeners,
    setActiveDiscussionId,
    user.id,
    loadDiscussion,
    discussion.ended,
  ]);

  useEffect(() => {
    if (user.id && activeDiscussionId) {
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#E02020');
    } else if (discussion.ended) {
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#222222');
    }

    return () => {
      if (user.id && activeDiscussionId) {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#222222');
      }
    };
  }, [user.id, discussion, activeDiscussionId]);

  useEffect(() => {
    if (user.id && !user.name) {
      updateName();
    }
  }, [user.id, user.name, updateName]);
}
