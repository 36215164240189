import classNames from 'classnames';
import Button from '../../Button';

function NavOption({ href, as, onClick, Icon, children, active }) {
  const wrapperClasses = classNames('navOption', { 'navOption--active': active });
  return (
    <Button variant="clear" className={wrapperClasses} as={as} href={href} onClick={onClick}>
      <Icon className="icon" aria-hidden="true" />
      <span>{children}</span>
    </Button>
  );
}

export default NavOption;
