import React from 'react';

export default function Skeleton() {
  return (
    <section className="user-profile animate-pulse">
      <section className="wrapper" variant="clear">
        <section className="profile bg-gray-200 rounded-full w-10 h-10" />
        <section className="details">
          <section className="w-full space-y-2">
            <section className="bg-gray-200 rounded-full w-20 h-2" />
            <section className="bg-gray-200 rounded-full w-16 h-2" />
            <section className="bg-gray-200 rounded-full w-full h-2" />
            <section className="bg-gray-200 rounded-full w-9/12 h-2" />
          </section>
        </section>
      </section>
    </section>
  );
}
