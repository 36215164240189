import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useRedirectTo from '../../../lib/hooks/useRedirectTo';
import { useUser } from '../../../lib/user';
import Button from '../../Button';
import RoomListenersPreview from '../../Entities/RoomListenersPreview';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import Modal from '../Modal';

export default function DiscussionModal({ discussion, onConfirm }) {
  const router = useRouter();
  const { discussionSlug } = router.query;
  const [isOpen, setIsOpen] = useState(false);

  const redirectTo = useRedirectTo();

  const user = useUser().state.user;

  const audioEl = useRef();

  const isHost = user.id === discussion.host_id;

  const handleClose = () => {
    setIsOpen(false);
    router.replace(window.location.pathname);
  };

  const handleJoin = () => {
    audioEl.current.play();
    onConfirm(true);
    return handleClose();
  };

  useEffect(() => {
    if (discussionSlug) {
      setIsOpen(true);
    }
  }, [discussionSlug]);

  return (
    <Modal isOpen={isOpen} transparent onClose={handleClose}>
      <section className="pt-16">
        <section className="p-4 md:p-8 bg-white rounded-lg shadow-lg space-y-5">
          <section>
            <h3 className="font-bold text-2xl">{discussion.title}</h3>
            <p className="text-gray-600">
              A discussing by {discussion.user.name} for {discussion.room.entity_title}
            </p>
          </section>
          <section>
            <RoomListenersPreview discussion={discussion} />
          </section>
          {user.id && (
            <section className="space-y-2">
              {!isHost && (
                <p className="text-gray-600 text-center">Your mic will be off to start with</p>
              )}
              <Button onClick={handleJoin} fullWidth size="large">
                {isHost ? 'Start your discussion' : 'Join discussion'}
              </Button>
            </section>
          )}
          {!user.id && <RegistrationForm redirect={redirectTo} hideTitle />}
          <audio style={{ display: 'none' }} ref={audioEl} src="/silence.mp3" controls />
        </section>
      </section>
    </Modal>
  );
}
