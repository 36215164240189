import actionTypes from './actionTypes';

const createState = (all, next) => {
  const children = next.children || [];
  const { parent, ...message } = next;
  if (parent?.id) {
    if (!all.byId[parent.id]) {
      all.ids.push(parent.id);
    }
    all.byId[parent.id] = parent;
    parent.children.reduce((a, n) => {
      if (!a.byId[n.id]) {
        a.ids.push(n.id);
        a.byId[n.id] = { ...n, room: message.room };
      }
      return a;
    }, all);
  }
  if (!all.byId[message.id]) {
    all.ids.push(message.id);
  }
  children.reduce((a, n) => {
    if (!a.byId[n.id]) {
      a.ids.push(n.id);
      a.byId[n.id] = { ...n, room: message.room };
    }
    return a;
  }, all);
  all.byId[message.id] = message;
  return all;
};

const updateEngagement = ({ message, type, reaction }) => {
  const likeIndex = message[type].findIndex(
    (action) => action.message_id === reaction.message_id && action.user_id === reaction.user_id,
  );

  if (likeIndex !== -1) {
    message[type].splice(likeIndex, 1, reaction);
  } else {
    message[type].push(reaction);
  }
  return message;
};

const newState = () => ({
  ids: [],
  byId: {},
});

export const initialState = () => ({
  isLoading: true,
  messages: newState(),
  canFetchMore: false,
  users: {},
});

const messagesReducer = (draft, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_FINISHED:
    case actionTypes.REQUEST_ENTITY_MESSAGES_FAILURE: {
      draft.isLoading = false;
      break;
    }
    case actionTypes.REQUEST_ENTITY_ROOMS: {
      draft.isLoading = true;
      break;
    }
    case actionTypes.REQUEST_USERS_SUCCESS: {
      const { user } = action;

      if (!draft.users[user.id]) {
        draft.users[user.id] = user;
      }
      break;
    }
    case actionTypes.SET_MESSAGES_CAN_FETCH: {
      const { canFetchMore } = action;
      draft.canFetchMore = canFetchMore;
      break;
    }
    case actionTypes.SET_MESSAGE_LIKE: {
      const { like } = action;

      const draftMessage = {
        ...draft.messages.byId[like.message_id],
        id: like.message_id,
        likes: draft.messages.byId[like.message_id]?.likes ?? [],
        insights: draft.messages.byId[like.message_id]?.insights ?? [],
        freshs: draft.messages.byId[like.message_id]?.freshs ?? [],
      };

      draft.messages.byId[draftMessage.id] = updateEngagement({
        message: draftMessage,
        type: 'likes',
        reaction: like,
      });

      break;
    }
    case actionTypes.SET_MESSAGE_INSIGHT: {
      const { insight } = action;

      const draftMessage = {
        ...draft.messages.byId[insight.message_id],
        id: insight.message_id,
        insights: draft.messages.byId[insight.message_id]?.insights ?? [],
        freshs: draft.messages.byId[insight.message_id]?.freshs ?? [],
        likes: draft.messages.byId[insight.message_id]?.likes ?? [],
      };

      draft.messages.byId[draftMessage.id] = updateEngagement({
        message: draftMessage,
        type: 'insights',
        reaction: insight,
      });

      break;
    }
    case actionTypes.SET_MESSAGE_FRESH: {
      const { fresh } = action;

      const draftMessage = {
        ...draft.messages.byId[fresh.message_id],
        id: fresh.message_id,
        freshs: draft.messages.byId[fresh.message_id]?.freshs ?? [],
        insights: draft.messages.byId[fresh.message_id]?.insights ?? [],
        likes: draft.messages.byId[fresh.message_id]?.likes ?? [],
      };

      draft.messages.byId[draftMessage.id] = updateEngagement({
        message: draftMessage,
        type: 'freshs',
        reaction: fresh,
      });

      break;
    }
    case actionTypes.REQUEST_ENTITY_MESSAGES_SUCCESS: {
      const { messages } = action;
      draft.messages = messages.reduce(createState, draft.messages);

      break;
    }
    case actionTypes.REMOVE_MESSAGE: {
      const {
        message: { id },
      } = action;
      const oldMessage = draft.messages.byId[id];
      const { room_id, children, updated_at, room } = oldMessage;
      draft.messages.byId[id] = {
        id,
        room_id,
        children,
        is_deleted: true,
        updated_at,
        room,
      };
      break;
    }
    default: {
      throw Error(`Uncaught action of type ${action.type}`);
    }
  }
};

export default messagesReducer;
