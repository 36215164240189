import { FiChevronRight } from 'react-icons/fi';
import Button from '../Button';
import Image from '../Image';

export default function UserProfile({ user }) {
  return (
    <section className="user-profile group">
      <Button as="a" href={`/${user.username}`} className="wrapper" variant="clear">
        <section className="profile">
          <Image
            src={user.photo}
            width="64"
            height="64"
            layout="intrinsic"
            className="object-cover"
          />
        </section>
        <section className="details">
          <section>
            <h2>
              {user.name} <span>@{user.username}</span>
            </h2>
            {user.bio && <p>{user.bio}</p>}
          </section>
          <section>
            <FiChevronRight className="icon group-hover:text-red-500 group-focus:text-red-500 " />
          </section>
        </section>
      </Button>
    </section>
  );
}
