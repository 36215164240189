import { createContext } from 'react';
import { initialState } from './messagesReducer';

const MessagesContext = createContext({
  state: initialState(),
  dispatch: () => {},
  freshMessage: () => {},
  insightMessage: () => {},
  likeMessage: () => {},
  addMessage: () => {},
  fetchEntityMessages: () => {},
  setMessages: () => {},
  messages: [],
  messagesStartRef: null,
  messagesEndRef: null,
  entity: {},
});

export default MessagesContext;
