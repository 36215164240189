import classNames from 'classnames';
import isDeepEqual from 'lodash.isequal';
import React, { memo, useEffect, useState } from 'react';
import authFetch from '../../../../lib/utils/authFetch';
import slugify from '../../../../lib/utils/slugify';
import Button from '../../../Button';
import Image from '../../../Image';

function EntityCard({ entity, alt, priority }) {
  const [photo, setPhoto] = useState('');
  const wrapperClasses = classNames('entity-card group', {
    alt,
  });
  useEffect(() => {
    if (!entity.photo) {
      (async () => {
        const data = await authFetch(`${process.env.NEXT_PUBLIC_API}/entity/title/${entity.id}`);
        setPhoto(data.photo);
      })();
    } else {
      setPhoto(entity.photo);
    }
  }, [entity]);

  return (
    <Button
      as="a"
      href={`/e/title/${slugify(entity.title)}-${entity.id}`}
      variant="clear"
      className={wrapperClasses}
    >
      <section className="content-wrapper">
        <section className="image_wrapper">
          <Image
            src={photo}
            className="entity-image"
            alt={`${entity.title} ${entity.type} cover`}
            priority={priority}
            width={160}
            height={250}
            layout="responsive"
          />
        </section>
        {!alt && (
          <section className="entity-content">
            <p>
              {entity.title}
              {entity.year ? ` (${entity.year})` : ''}
            </p>
          </section>
        )}
      </section>
    </Button>
  );
}

export default memo(EntityCard, isDeepEqual);
