import classNames from 'classnames';
import React from 'react';

export default function Card({ title, children, Icon, className }) {
  const wrapperClasses = classNames('base-card', className);
  return (
    <section className={wrapperClasses}>
      <section className="wrapper">
        <section className="content">
          {title && (
            <section className="heading">
              {typeof title === 'string' && <h3>{title}</h3>}
              {typeof title !== 'string' && React.isValidElement(title) && title}
              {Icon && (
                <section>
                  <Icon className="icon" />
                </section>
              )}
            </section>
          )}
          {typeof children === 'string' && <p>{children}</p>}
          {typeof children !== 'string' && children}
        </section>
      </section>
    </section>
  );
}
