import { getSupabase } from '../../supabase';

export const getDiscussions = async ({
  select = '*, room:rooms_with_discussions!discussions_room_id_fkey',
  eq,
}) => {
  const supabase = await getSupabase();

  try {
    let discussions = [];
    if (eq) {
      const { data } = await supabase
        .from('discussion_with_engagements')
        .select(select)
        .eq(eq.field, eq.param)
        .eq('ended', false)
        .order('inserted_at', { ascending: false });
      discussions = data;
    } else {
      const { data } = await supabase
        .from('discussion_with_engagements')
        .select(select)
        .eq('ended', false)
        .order('inserted_at', { ascending: false });
      discussions = data;
    }

    return { discussions };
  } catch (error) {
    return { error, discussions: [] };
  }
};

export const getDiscussionsEntities = async ({
  select = '*, room:rooms_with_discussions!discussions_room_id_fkey',
}) => {
  const supabase = await getSupabase();

  try {
    const { data: discussions } = await supabase
      .from('discussion_with_engagements')
      .select(select)
      .order('inserted_at', { ascending: false });

    return { discussions };
  } catch (error) {
    return { error, discussions: null };
  }
};

export const getDiscussionsWithRecordings = async ({ roomId }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussions, error } = await supabase
      .from('discussion_with_engagements')
      .select('id, title, stream_link')
      .eq('room_id', roomId)
      .not('stream_link', 'is', null)
      .order('inserted_at', { ascending: false });

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussions, error };
  } catch (error) {
    return { error, discussions: [] };
  }
};

export const getDiscussion = async ({
  slug,
  select = '*, listeners:discussion_listeners(user:users(*))',
  eq = { field: 'slug', param: slug },
}) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion, error } = await supabase
      .from('discussions_with_room')
      .select(select)
      .eq(eq.field, eq.param)
      .eq('ended', false)
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussion, error };
  } catch (error) {
    console.error('🥵 ~ error', error);
    return { error, discussion: {} };
  }
};

export const getDiscussionEntity = async ({
  slug,
  select = '*, listeners:discussion_listeners(user:users(*))',
  eq = { field: 'slug', param: slug },
}) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion, error } = await supabase
      .from('discussions_with_room')
      .select(select)
      .eq(eq.field, eq.param)
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussion, error };
  } catch (error) {
    console.error('🥵 ~ error', error);
    return { error, discussion: null };
  }
};

export const getDiscussionById = async ({ discussionId, select }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion, error } = await supabase
      .from('discussion_with_engagements')
      .select(select)
      .eq('id', discussionId)
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussion, error };
  } catch (error) {
    console.error('🥵 ~ error', error);
    return { error, discussion: {} };
  }
};

export const updateDiscussion = async ({ id, update }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion, error } = await supabase
      .from('discussions')
      .update(update)
      .match({ id })
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussion, error };
  } catch (error) {
    console.error('🥵 ~ error', error);
    return { error, discussion: {} };
  }
};

export const endDiscussion = async ({ discussionId }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion, error } = await supabase
      .from('discussions')
      .update({ ended: true })
      .match({ id: discussionId })
      .single();

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { discussion, error };
  } catch (error) {
    console.error('🥵 ~ error', error);
    return { error, discussion: {} };
  }
};

export const createDiscussion = async ({
  insert = [],
  select = 'id, slug, room:room_id(id, title,)',
}) => {
  const supabase = await getSupabase();

  try {
    const { data: discussion } = await supabase
      .from('discussions')
      .insert(insert)
      .select(select)
      .single();

    return { discussion, error: null };
  } catch (error) {
    return { error, discussion: null };
  }
};

export const createDiscussionListeners = async ({ insert = [] }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussionListeners } = await supabase
      .from('discussion_listeners')
      .upsert(insert);

    return { discussionListeners };
  } catch (error) {
    return { error, discussionListeners: [] };
  }
};

export const removeDiscussionListeners = async ({ discussionId, userId }) => {
  const supabase = await getSupabase();

  try {
    const { data: discussionListeners } = await supabase
      .from('discussion_listeners')
      .delete()
      .match({
        discussion_id: discussionId,
        user_id: userId,
      });

    return { discussionListeners };
  } catch (error) {
    return { error, discussionListeners: [] };
  }
};
