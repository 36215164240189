export default {
  REQUEST_ENTITY: 'entity/REQUEST_ENTITY',
  REQUEST_FINISHED: 'entity/REQUEST_FINISHED',
  REQUEST_USERS_SUCCESS: 'entity/REQUEST_USERS_SUCCESS',
  REQUEST_ENTITY_SUCCESS: 'entity/REQUEST_ENTITY_SUCCESS',
  REQUEST_ENTITY_FAILURE: 'entity/REQUEST_ENTITY_FAILURE',
  REQUEST_ROOMS_SUCCESS: 'entity/REQUEST_ROOMS_SUCCESS',
  REMOVE_DISCUSSION: 'entity/REMOVE_DISCUSSION',
  REQUEST_DISCUSSION_SUCCESS: 'entity/REQUEST_DISCUSSION_SUCCESS',
  SET_ENTITY_CAN_FETCH: 'entity/SET_ENTITY_CAN_FETCH',
  SET_ROOM: 'entity/SET_ROOM',
  REMOVE_ROOM: 'entity/REMOVE_ROOM',
  SET_ENTITY: 'entity/SET_ENTITY',
};
