import { createContext, useCallback, useEffect, useState } from 'react';
import { AlertList } from './AlertList';

export const AlertContext = createContext({});

export const AlertProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const handleMessage = ({ message, dismissable = true }) => {
    setMessages([{ ...message, dismiss: () => setMessages([]) }]);
    if (dismissable) {
      setTimeout(() => {
        setMessages((prevMessages) => prevMessages.slice(1));
      }, 5000);
    }
    return Promise.resolve();
  };

  const removeMessage = useCallback(() => {
    if (messages.length) {
      setTimeout(() => {
        setMessages((prevMessages) => prevMessages.slice(1));
      }, 1000);
    }
  }, [messages]);

  useEffect(() => {
    document.addEventListener('scroll', removeMessage);
    return () => {
      document.removeEventListener('scroll', removeMessage);
    };
  }, [removeMessage]);

  return (
    <AlertContext.Provider value={{ messages, handleMessage }}>
      {!!messages.length && <AlertList messages={messages} />}
      {children}
    </AlertContext.Provider>
  );
};
