/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import isDeepEqual from 'lodash.isequal';
import { memo } from 'react';
import { useAlert } from '../../../../lib/alert';
import useAuth from '../../../../lib/auth/useAuth';
import { useEntity } from '../../../../lib/entity';
import { useUser } from '../../../../lib/user';
import { formatNumberShort } from '../../../../lib/utils/format';
import Button from '../../../Button';
import { Logo } from '../../../Icons';
import Image from '../../../Image';
import RoomListenersPreview from '../../RoomListenersPreview';

const MAX_COUNT = 5;

function DiscussionCard({ discussion, noInteraction }) {
  const handleMessage = useAlert().handleMessage;
  const login = useAuth().login;
  const stateUser = useUser().state.user;

  const activeDiscussionId = useEntity().activeDiscussionId;
  const setActiveDiscussionId = useEntity().setActiveDiscussionId;

  const wrapperClasses = classNames('card', { noInteraction });
  const titleClasses = classNames('line-clamp-1');

  const entity = { title: discussion.room?.entity_title, photo: discussion.room?.entity_photo };

  const handleClick = () => {
    if (!stateUser.id) {
      return login();
    }
    if (noInteraction || discussion.ended) {
      return null;
    }
    if (activeDiscussionId) {
      return handleMessage({
        message: {
          message: 'Leave your current discussion to join this one',
          type: 'warning',
        },
      });
    }
    if (discussion.host_id === stateUser.id) {
      if (typeof navigator.mediaDevices === 'undefined') {
        return handleMessage({
          message: {
            message:
              "You can't start your discussion on this device. It does not support audio streaming on the web.",
            type: 'error',
          },
          dismissable: false,
        });
      }
    }
    return setActiveDiscussionId(discussion.id);
  };

  const handleLoadClick = () => {
    const loadedAudio = new Audio('/silence.mp3');
    loadedAudio.load();
    loadedAudio.addEventListener('canplaythrough', () => {
      console.log('loaded audio');
      loadedAudio.play();
      handleClick();
    });
  };

  return (
    <Button as="section" onClick={handleLoadClick} className={wrapperClasses} variant="clear">
      <section className="wrapper">
        <section className="content">
          <section className="flex items-center justify-between -mx-2">
            <section className="flex items-center space-x-1 px-2">
              <section className="e-image">
                <Image
                  className="bg-gray-300 w-full h-full object-cover"
                  src={entity?.photo}
                  alt={`${entity?.title} main`}
                />
              </section>
              <h3 className="flex items-center px-2">
                <Logo className="w-4 mr-2" hideTag />{' '}
                <span className={titleClasses}>Discussing - {discussion.title}</span>
              </h3>
            </section>

            <section className="px-2">
              <section className="live-tag">
                <span className="signal">
                  <span className="pulse-small" />
                  <span className="pulse" />
                </span>
                <span className="text">Live</span>
              </section>
            </section>
          </section>
          <p className="description">From room: {discussion.room?.title}</p>
          {!!discussion.listeners.length && (
            <>
              <RoomListenersPreview discussion={discussion} />
              <section>
                <p className="names">
                  {discussion.listeners.map(({ user }, index) => {
                    const leftOverMemberCount = discussion.listeners.length - index + 1;
                    let names = '';

                    if (index < MAX_COUNT) {
                      names = user.name?.split(' ')[0];

                      if (discussion.listeners.length < 2) {
                        return `${names} in the room`;
                      }

                      names = `${names}${
                        discussion.listeners.length > index + 1 && index + 1 < MAX_COUNT
                          ? ', '
                          : ' '
                      }`;
                    }

                    if (index > 4) {
                      if (
                        discussion.listeners.length > MAX_COUNT &&
                        leftOverMemberCount > MAX_COUNT
                      ) {
                        names = `${names} and ${formatNumberShort(
                          leftOverMemberCount,
                        )} others in the room`;
                      }
                    } else if (index && index < 4) {
                      names = `${names} in the room`;
                    }

                    return names;
                  })}
                </p>
              </section>
            </>
          )}
        </section>
      </section>
      {/* <audio style={{ display: 'none' }} ref={audioEl} src="/silence.mp3" controls /> */}
    </Button>
  );
}

export default memo(DiscussionCard, isDeepEqual);
