import { getSupabase, PER_PAGE } from '../../supabase';

export const getMessage = async ({ messageId, select = '*' }) => {
  const supabase = await getSupabase();

  try {
    const { data: message, error } = await supabase
      .from('messages_with_engagements')
      .select(select)
      .eq('id', messageId)
      .single();

    if (error) {
      console.log('🥵 ~ error', error);
    }

    return { message, error: null };
  } catch (error) {
    return { error, message: null };
  }
};
export const getConversation = async ({ messageId }) => {
  const supabase = await getSupabase();

  try {
    const { data: messages, error } = await supabase
      .rpc('grab_conversation', {
        message_id: messageId,
      })
      .neq('id', messageId);

    if (error) {
      console.log('🥵 ~ error', error);
    }

    return { messages, error: null };
  } catch (error) {
    return { error, messages: null };
  }
};

export const getMessageChildren = async ({ messageId }) => {
  const supabase = await getSupabase();

  try {
    const { data: messages } = await supabase
      .from('messages_with_engagements')
      .select('*')
      .eq('parent_id', messageId);

    return { messages, error: null };
  } catch (error) {
    return { error, messages: [] };
  }
};

export const getMessages = async ({ select = '*' }) => {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase.from('messages_with_engagements').select(select);
    const messages = data || [];

    if (error) {
      console.log('🥵 ~ error', error);
    }

    return { messages, error };
  } catch (error) {
    return { error, messages: [] };
  }
};

export const getRoomMessages = async ({ rooms, rangeFrom = 0, rangeTo = PER_PAGE }) => {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase
      .from('messages_with_engagements')
      .select('*')
      .in('room_id', rooms)
      .order('id', { ascending: false })
      .range(rangeFrom, rangeTo);

    const messages = data || [];

    if (error) {
      console.log('🥵 ~ error', error);
    }

    return { messages, error };
  } catch (error) {
    return { error, messages: [] };
  }
};

export const getUserMessages = async ({
  userId,
  select = '*',
  rangeFrom = 0,
  rangeTo = PER_PAGE,
}) => {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase
      .from('messages_with_engagements')
      .select(select)
      .order('id', { ascending: false })
      .eq('user_id', userId)
      .range(rangeFrom, rangeTo);

    const messages = data || [];

    if (error) {
      console.log('🥵 ~ error', error);
    }

    return { messages, error };
  } catch (error) {
    console.log('🥵 ~ error', error);
    return { error, messages: [] };
  }
};

const getPercentile = (percent) => {
  if (percent >= 0.95) {
    return 0.95;
  }
  if (percent >= 0.85) {
    return 0.85;
  }
  if (percent >= 0.75) {
    return 0.75;
  }

  return 0;
};

export const getEngagementStats = async ({ userId, type }) => {
  const supabase = await getSupabase();

  try {
    const { data } = await supabase
      .from('messages_with_engagements')
      .select(`${type.label}_count`)
      .eq('user_id', userId)
      .neq(`${type.label}_count`, 0);

    const count = data.reduce((all, next) => {
      all += next[`${type.label}_count`];
      return all;
    }, 0);

    const { count: totalCount } = await supabase
      .from(`message_${type.value}`)
      .select('*', { count: 'exact' })
      .neq('user_id', userId);

    const percent = ((100 / totalCount) * count) / 100;

    const percentile = getPercentile(percent);

    return { percentile, count, error: null };
  } catch (error) {
    return { error, percentile: 0 };
  }
};

export const deleteMessage = async (id) => {
  const supabase = await getSupabase();

  try {
    const { data: message } = await supabase
      .from('messages')
      .update({ is_deleted: true })
      .match({ id })
      .select('*')
      .single();

    return { message, error: null };
  } catch (error) {
    return { error, message: {} };
  }
};

export const getMessageUsers = async ({ messageId, table }) => {
  const supabase = await getSupabase();
  let users = [];
  try {
    const { data } = await supabase
      .from(table)
      .select(`user:users_selection_table!user_id(*)`)
      .eq('message_id', messageId)
      .eq('marked', true);

    if (data) {
      users = data.map(({ user }) => user);
    }

    return { users, error: null };
  } catch (error) {
    return { error, users: [] };
  }
};
