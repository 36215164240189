import { useEffect, useMemo, useReducer } from 'react';
import SetNameModal from '../../components/Modals/SetNameModal';
import SetUsernameModal from '../../components/Modals/SetUsernameModal';
import { getSupabase } from '../supabase';
import * as actions from './actions';
import actionTypes from './actionTypes';
import UserContext from './UserContext';
import userReducer, { initialState } from './userReducer';

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, initialState());
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = useMemo(() => ({ state, dispatch }), [state]);

  const userState = value.state;
  const userDispatch = value.dispatch;

  const requestUpdateName = () => actions.requestUpdateName(userDispatch);
  useEffect(() => {
    let authListener;
    (async () => {
      const supabase = await getSupabase();

      if (!userState.user.id) {
        const { user } = await supabase.auth.refreshSession();
        if (user) {
          global.analytics.identify(
            user.id,
            {},
            {
              context: {
                ip: '0.0.0.0',
                page: {
                  path: '/identify',
                  url: `${process.env.NEXT_PUBLIC_DOMAIN}identify`,
                },
              },
            },
          );
          actions.findOrCreateUser(userDispatch, user);
        }
      }

      const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
        authListener = data;
        const sessionUser = session?.user ?? {};
        await actions.setServerSession(event, session);
        if (sessionUser.id) {
          if (!userState.user.id) {
            actions.findOrCreateUser(userDispatch, sessionUser);
          }
        } else {
          userDispatch({ type: actionTypes.SET_USER, user: {} });
        }
      });
    })();

    return () => {
      if (authListener) {
        authListener.unsubscribe();
      }
    };
  }, [userDispatch, userState.user.id]);

  return (
    <UserContext.Provider value={{ ...value, actions: { ...actions, requestUpdateName } }}>
      <SetUsernameModal isOpen={userState.addDetails} />
      <SetNameModal isOpen={userState.addName} user={userState.user} />
      {children}
    </UserContext.Provider>
  );
}

// UserProvider.whyDidYouRender = true;

export default UserProvider;
