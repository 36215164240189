import actionTypes from './actionTypes';

export const initialState = () => ({
  addDetails: false,
  addName: false,
  isFetching: false,
  options: {},
  user: {},
});

function userReducer(state = initialState(), action) {
  switch (action.type) {
    case actionTypes.SET_USER: {
      const { user } = action;
      return { ...state, user, isFetching: false, addDetails: false, addName: false };
    }
    case actionTypes.REQUEST_USER: {
      return { ...state, isFetching: true };
    }
    case actionTypes.REQUEST_USER_DETAILS: {
      const { addDetails, options } = action;
      return { ...state, addDetails, options: options ?? {} };
    }
    case actionTypes.REQUEST_USER_NAME: {
      const { addName } = action;
      return { ...state, addName };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
