import { useEffect, useState } from 'react';
import { useEntity } from '../../lib/entity';
// import Button from '../Button';
import EntityCard, { EntitySkeleton } from '../Entities/Entity/EntityCard';

export default function Trending() {
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useEntity().dispatch;
  const fetchTrendingEntities = useEntity().fetchTrendingEntities;

  useEffect(() => {
    (async () => {
      try {
        const { entities: data } = await fetchTrendingEntities(dispatch);
        setEntities(data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, fetchTrendingEntities]);

  if (!isLoading && !entities.length) {
    return null;
  }

  return (
    <>
      <section className="content">
        {/* <Button as="a" href="#feed" className="sr-only">
          Skip to main content
        </Button> */}
        <h2>Trending</h2>
        <section className="entities">
          {isLoading && [...Array(12).keys()].map((i) => <EntitySkeleton key={i} />)}
          {entities &&
            entities.map((entity) => <EntityCard key={`entity-${entity.id}`} entity={entity} />)}
        </section>
      </section>
    </>
  );
}

// Trending.whyDidYouRender = true;
