import classNames from 'classnames';
import ListeningProfile from '../ListeningProfile';

export default function RoomListenersPreview({ discussion }) {
  const wrapperClasses = classNames('grid grid-flow-col gap-5', {
    'auto-cols-max': discussion.listeners.length !== 5,
  });
  return (
    <section className={wrapperClasses}>
      {discussion.listeners.map(
        ({ user }, index) =>
          index < 4 && (
            <ListeningProfile
              // eslint-disable-next-line react/no-array-index-key
              key={`listener-${user.username}-${discussion.id}-${index}`}
              src={user.photo}
              alt={`${user.name} profile`}
            >
              <section className=" text-center">
                <p className="line-clamp-1 max-w-[8ch]">{user.name}</p>
                {discussion.host_id === user.id && <p className="text-gray-700 text-sm">Host</p>}
              </section>
            </ListeningProfile>
          ),
      )}
      {discussion.listeners.length > 5 && (
        <ListeningProfile>+{discussion.listeners.length}</ListeningProfile>
      )}
    </section>
  );
}
