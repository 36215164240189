import classNames from 'classnames';
import Image from '../../Image';

export default function ListeningProfile({ alt, src, children }) {
  const wrapperClasses = classNames('listener', {
    'text-sm': !!children,
  });

  return (
    <section className="flex flex-col items-center">
      <section className={wrapperClasses}>
        <Image alt={alt} src={src} className="object-cover" />
        {!src && children}
      </section>
      {src && children}
    </section>
  );
}
