import classNames from 'classnames';
import { useState } from 'react';
import { useUser } from '../../../lib/user';
import Button from '../../Button';
import TextInput from '../../FormFields/TextInput';
import Modal from '../../Modals/Modal';

export default function SetNameModal({ isOpen, user, onClose }) {
  const dispatch = useUser().dispatch;
  const actions = useUser().actions;

  const fieldContainerClasses = classNames('bg-white p-8 rounded-lg shadow-lg space-y-4');

  const [name, setName] = useState(user.name);

  const hasChangedValues = user.name !== name;

  const handleChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (hasChangedValues) {
      await actions.updateUser(dispatch, {
        id: user.id,
        name,
      });
      global.analytics.track(
        'Updated Name',
        {
          category: 'Profiles',
        },
        {
          page: {
            path: `/user-details/name`,
            url: `${process.env.NEXT_PUBLIC_DOMAIN}user-details/name`,
          },
          context: {
            ip: '0.0.0.0',
          },
        },
      );
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} transparent onClose={onClose}>
      <form className={fieldContainerClasses} onSubmit={handleSubmit}>
        <section className="space-y-4">
          <TextInput
            className="w-full"
            inputClasses="border p-2"
            label="Your name"
            name="name"
            placeholder="Your name"
            value={name}
            onChange={handleChange}
          />
        </section>
        <Button disabled={!name || name === user.name} type="submit">
          {user.name ? 'Update' : 'Set'} your name
        </Button>
      </form>
    </Modal>
  );
}
