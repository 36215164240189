import { useEffect } from 'react';

export default function useFetchEntity({ entityId, type, actions }) {
  useEffect(() => {
    (async () => {
      if (entityId && type) {
        actions.request();
        try {
          await actions.fetchRooms(entityId);
        } catch (error) {
          console.error('🥵 ~ error', error);
        } finally {
          actions.finished();
        }
      } else if (!entityId) {
        await actions.fetchRooms();
        actions.finished();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, type]);
}
