import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useState } from 'react';
import useRedirectTo from '../../lib/hooks/useRedirectTo';
import { getSupabase } from '../../lib/supabase';
import TextInput from '../FormFields/TextInput';
import { Spinner } from '../Icons';
import Button from './../Button';
import SignInButton from './../Button/SignInButton';

const validEmail = (email) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

export default function RegistrationForm({ hideTitle, redirect }) {
  const redirectTo = useRedirectTo();
  const wrapperClasses = classNames({
    'bg-white p-8 rounded-xl': !hideTitle,
  });
  const headerClasses = classNames('text-2xl font-bold mb-2');
  const bodyClasses = classNames('space-y-8');
  const buttonContainerClasses = classNames('flex justify-around -mx-2');
  const buttonContentClasses = classNames('px-2 flex-grow flex');
  const fieldContainerClasses = classNames('space-y-4');
  const fieldClasses = classNames('');

  const [values, setValues] = useState({
    email: '',
  });

  const [emailValid, setEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // const handleSignUp = () =>
  //   dispatch({ type: actions.SET_AUTH_VIEW, isAuthOpen: true, authType: 'sign_up' });

  const handleChange = (e, name) => {
    const value = e.target.value;
    if (name === 'email') {
      setEmailValid(validEmail(value));
    }
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const Wrapper = hideTitle ? 'h3' : 'h4';

  return (
    <section className={wrapperClasses}>
      {!hideTitle && (
        <section className={headerClasses}>
          <h3>Welcome to FTVBs</h3>
        </section>
      )}
      <section className={bodyClasses}>
        <Transition show>
          <Transition.Child
            enter="ease-in duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <section className="space-y-2">
              <Wrapper>Continue with</Wrapper>
              <section className={buttonContainerClasses}>
                <section className={buttonContentClasses}>
                  <SignInButton
                    provider="google"
                    disabled={isLoading}
                    redirectTo={redirect || redirectTo}
                  />
                </section>
                <section className={buttonContentClasses}>
                  <SignInButton
                    provider="twitter"
                    disabled={isLoading}
                    redirectTo={redirect || redirectTo}
                  />
                </section>
                <section className={buttonContentClasses}>
                  <SignInButton
                    provider="discord"
                    disabled={isLoading}
                    redirectTo={redirect || redirectTo}
                  />
                </section>
                <section className={buttonContentClasses}>
                  <SignInButton
                    provider="facebook"
                    disabled={isLoading}
                    redirectTo={redirect || redirectTo}
                  />
                </section>
              </section>
            </section>
          </Transition.Child>
        </Transition>
        <section className={fieldContainerClasses}>
          <section className={fieldClasses}>
            <TextInput
              className="w-full space-y-2"
              inputClasses="border p-2"
              label="Or sign in with email"
              name="email"
              type="email"
              placeholder="Your email"
              value={values.email}
              disabled={isLoading}
              onChange={(e) => handleChange(e, 'email')}
              endButton={
                <Button
                  disabled={!emailValid || isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    const supabase = await getSupabase();

                    const { error } = await supabase.auth.signIn(
                      {
                        email: values.email.toLowerCase().trim(),
                      },
                      {
                        redirectTo: redirect || redirectTo,
                      },
                    );
                    if (!error) {
                      setEmailSent(true);
                      setTimeout(() => {
                        setEmailSent(false);
                      }, 2000);
                    }
                    setIsLoading(false);
                  }}
                >
                  {isLoading && 'Sending email'}
                  {!isLoading && <>{!emailSent ? 'Sign in' : 'Check your email'}</>}
                  {isLoading && <Spinner className="ml-2" />}
                </Button>
              }
            />
            {values.email && !emailValid && (
              <p className="text-sm text-red-600">Email must be a valid email</p>
            )}
          </section>
        </section>
      </section>
    </section>
  );
}
