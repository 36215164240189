import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../lib/auth';
import { EntityProvider } from '../../../lib/entity';
import { usePage } from '../../../lib/page';
import { useUser } from '../../../lib/user';
import { DiscussionSkeleton } from '../../Entities/Discussion/DiscussionCard';
import { EntityHeaderSekelton } from '../../Entities/Entity/EntityHeader';
import { RoomSkeleton } from '../../Entities/RoomCard';
import Modal from '../../Modals/Modal';
import Nav from '../../Nav';
import RegistrationForm from '../../RegistrationForm';

const App = ({ children }) => {
  const setNavHeight = usePage().setNavHeight;
  const hideNav = usePage().hideNav;
  const hideHero = usePage().hideHero;
  const hideSide = usePage().hideSide;
  const hideAside = usePage().hideAside;
  const isOpen = usePage().state.isOpen;

  const isAuthOpen = useAuth().state.isAuthOpen;
  const closeAuth = useAuth().closeAuth;

  const isFetching = useUser().state.isFetching;

  const router = useRouter();

  const [isLoading, setIsLoading] = useState(true);

  const appClasses = classNames('app', {
    'hide-nav': hideNav,
    'hide-aside': hideAside,
  });

  const hendleResize = () => {
    const nav = document.querySelector('section[class="nav"]');
    const search = document.querySelector('section[id="search"]');
    if (!hideNav && !isOpen) {
      if (nav && window.innerWidth < 1024) {
        setNavHeight(nav.getBoundingClientRect().height);
      } else {
        setNavHeight(0);
      }
    } else if (isOpen) {
      if (nav && search && window.innerWidth < 1024) {
        setNavHeight(nav.getBoundingClientRect().height + search.getBoundingClientRect().height);
      } else {
        setNavHeight(0);
      }
    } else {
      setNavHeight(0);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', hendleResize);
    hendleResize();
    return () => {
      window.removeEventListener('resize', hendleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideNav, isOpen]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });
    router.events.on('routeChangeComplete', () => {
      setIsLoading(false);
    });
    window.onload = () => {
      setIsLoading(false);
    };
  }, [router.events]);

  return (
    <main className={appClasses}>
      {!hideNav && <Nav />}
      {isLoading &&
        !hideHero &&
        [
          '/[username]',
          '/e/[type]/[entitySlug]',
          '/e/[type]/[entitySlug]/rooms',
          '/e/[type]/[entitySlug]/details',
        ].includes(router.route) && (
          <section className="hero">
            {router.route !== '/[username]' && <EntityHeaderSekelton />}
          </section>
        )}
      <EntityProvider>
        {children}
        {isLoading &&
          !hideSide &&
          [
            '/e/[type]/[entitySlug]',
            '/e/[type]/[entitySlug]/r',
            '/e/[type]/[entitySlug]/r/details',
          ].includes(router.route) && (
            <>
              <section className="feed">
                <section className="space-y-4 px-4">
                  {[...Array(5).keys()].map((i) => (
                    <RoomSkeleton key={i} />
                  ))}
                </section>
              </section>
              <aside className="sidebar">
                <section className="space-y-4 px-4">
                  {[...Array(5).keys()].map((i) => (
                    <DiscussionSkeleton key={i} />
                  ))}
                </section>
              </aside>
            </>
          )}
      </EntityProvider>
      {!isFetching && (
        <Modal isOpen={isAuthOpen} onClose={closeAuth} transparent>
          <RegistrationForm />
        </Modal>
      )}
    </main>
  );
};

export default App;
