import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useEntity } from '../../../../lib/entity';
import { usePage } from '../../../../lib/page';
import { useUser } from '../../../../lib/user';
import getIdFromSlug from '../../../../lib/utils/getIdFromSlug';
import Button from '../../../Button';
import { DiscussionSkeleton } from '../../Discussion/DiscussionCard';
import { RoomSkeleton } from '../../RoomCard';

const DiscussionCard = dynamic(() => import('../../Discussion/DiscussionCard'), {
  loading: DiscussionSkeleton,
});

const RoomCard = dynamic(() => import('../../RoomCard'), { loading: RoomSkeleton });

export default function EntityAside({ onCreate }) {
  const user = useUser().state.user;
  const entity = useEntity().entity;
  const rooms = useEntity().rooms;
  const discussions = useEntity().discussions;
  const discussion = useEntity().discussion;
  const activeDiscussionId = useEntity().activeDiscussionId;
  const hasMessages = useEntity().hasMessages;
  const isLoading = useEntity().state.isLoading;

  const setHideSide = usePage().setHideSide;

  const router = useRouter();
  const { route } = router;
  const { roomSlug, discussionSlug } = router.query;

  const roomId = getIdFromSlug(roomSlug);

  const getRooms = () => {
    if (discussion.id && discussionSlug) {
      return rooms.filter((r) => r.id !== discussion.room_id);
    }
    if (roomId) {
      return rooms.filter((r) => `${r.id}` !== roomId);
    }
    return rooms;
  };

  const asideRooms = getRooms();

  const getDiscussions = () => {
    if (activeDiscussionId) {
      return discussions.reduce((all, next) => {
        if (next.id !== activeDiscussionId || !user.id) {
          all.push(next);
        }
        return all;
      }, []);
    }
    return discussions;
  };

  const asideDiscussions = getDiscussions().sort((a, b) => a.id + b.id);

  useEffect(() => {
    if (!isLoading) {
      setHideSide(true);
    }
  }, [isLoading, setHideSide]);

  return (
    <section className="section side-bar">
      {route !== '/e/[type]/[entitySlug]/rooms' && hasMessages && (
        <section className="item min-h-[200px]">
          <section className="flex justify-between">
            <h2 className="font-bold text-2xl">Recent rooms</h2>
            {asideRooms.length > 3 && (
              <Button
                as="a"
                href={`/e/${entity.e_type}/${entity.id}/r`}
                className="underline"
                size="small"
                variant="clear"
              >
                View all
              </Button>
            )}
          </section>
          {!asideRooms.length && (
            <>
              <h2>
                💭
                <br />
                No {`${roomId || discussion.id ? 'other ' : ''}`}rooms for{' '}
                <strong>{entity?.title}</strong>
              </h2>
              {!(roomId || discussion.id) && (
                <Button size="small" onClick={onCreate}>
                  Create a room
                </Button>
              )}
            </>
          )}
          {asideRooms
            .filter((_, i) => i < 2)
            .map((room) => (
              <RoomCard side key={room.id} room={room} isRoomPage />
            ))}
        </section>
      )}
      <section className="item min-h-[200px]">
        <section className="flex justify-between">
          <h2 className="font-bold text-2xl">Live discussions</h2>
          {asideDiscussions.length > 3 && (
            <Button
              as="a"
              href={`/e/${entity.e_type}/${entity.id}/r`}
              className="underline"
              size="small"
              variant="clear"
            >
              View all
            </Button>
          )}
        </section>
        {!asideDiscussions.length && (
          <h2>
            💭
            <br />
            No {`${discussion.id ? 'other ' : ''}`}discussions going on right now for{' '}
            <strong>{entity?.title}</strong>
          </h2>
        )}
        {asideDiscussions
          .filter((_, i) => i < 2)
          .map((d) => (
            <DiscussionCard key={d.id} discussion={d} />
          ))}
      </section>
    </section>
  );
}
