import { useEffect } from 'react';
import { getSupabase } from '../../../supabase';
import { getMessage, getUser } from '../../../supabase/actions';

export default function useNotificationsListeners({ userId, users, actions }) {
  useEffect(() => {
    let notificationListeners;

    if (userId) {
      (async () => {
        const supabase = await getSupabase();

        // Listen for new notifications
        notificationListeners = supabase
          .from(`notifications:user_id=eq.${userId}`)
          .on('INSERT', async (payload) => {
            let notificationUser = users[payload.new.engager_id];
            if (!notificationUser) {
              const { user: apiUser } = await getUser({ userId: payload.new.engager_id });
              notificationUser = apiUser;
              actions.setUser(notificationUser);
            }
            const { message: post } = await getMessage({
              messageId: payload.new.post_id,
              select: 'message, parent_id',
            });
            let message = null;
            if (payload.new.message_id) {
              const messageResponse = await getMessage({
                messageId: payload.new.message_id,
                select: 'message, id',
              });
              message = messageResponse.message;
            }
            const notification = { ...payload.new, user: notificationUser, post, message };
            actions.addNotification(notification);
          })
          .on('UPDATE', async (payload) => {
            let notificationUser = users[payload.new.engager_id];
            if (!notificationUser) {
              const { user: apiUser } = await getUser({ userId: payload.new.engager_id });
              notificationUser = apiUser;
              actions.setUser(notificationUser);
            }
            const { message: post } = await getMessage({
              messageId: payload.new.post_id,
              select: 'message, parent_id',
            });
            let message = null;
            if (payload.new.message_id) {
              const messageResponse = await getMessage({
                messageId: payload.new.message_id,
                select: 'message, id',
              });
              message = messageResponse.message;
            }
            const notification = { ...payload.new, user: notificationUser, post, message };
            actions.addNotification(notification);
          })
          .subscribe();
      })();
      // Cleanup on unmount
      return () => {
        if (notificationListeners) {
          notificationListeners.unsubscribe();
        }
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
}
