import { useRouter } from 'next/router';
import { useMemo, useReducer } from 'react';
import { useUser } from '../user';
import actionTypes from './actionTypes';
import useGetNotifications from './hooks/useGetNotifications';
import useMarkNotificationsRead from './hooks/useMarkNotificationsRead/useMarkNotificationsRead';
import useNotificationsListeners from './hooks/useNotificationsListeners/useNotificationsListeners';
import NotificationsContext from './NotificationsContext';
import notificationsReducer, { initialState } from './notificationsReducer';

const NotificationsProvider = ({ children, serverUser }) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialState());

  const value = useMemo(() => ({ state, dispatch }), [state]);

  const notificationsState = value.state;
  const notificationsDispatch = value.dispatch;

  const notifications = notificationsState.notifications.ids.map(
    (nId) => notificationsState.notifications.byId[nId],
  );

  const unreadNotifications = notifications.filter((n) => !n.read);

  const user = useUser().state.user;

  const router = useRouter();
  const { route } = router;

  useGetNotifications({
    userId: user.id,
    actions: {
      setUser: (newUser) => {
        if (!notificationsState.users[!newUser.id]) {
          dispatch({ type: actionTypes.SET_USER, user: newUser });
        }
      },
      setNotifications: (newNotifications) =>
        dispatch({
          type: actionTypes.REQUEST_NOTIFICATIONS_SUCCESS,
          notifications: newNotifications,
        }),
    },
  });

  useNotificationsListeners({
    userId: user.id,
    users: notificationsState.users,
    actions: {
      setUser: (newUser) => {
        if (!notificationsState.users[!newUser.id]) {
          dispatch({ type: actionTypes.SET_USER, user: newUser });
        }
      },
      addNotification: (notification) =>
        dispatch({ type: actionTypes.ADD_NOTIFICATION, notification }),
    },
  });

  useMarkNotificationsRead({
    expired: !serverUser,
    userId: user.id,
    notifications,
    route,
  });

  return (
    <NotificationsContext.Provider
      value={{
        dispatch: notificationsDispatch,
        state: notificationsState,
        notifications,
        unreadNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
