import { useRouter } from 'next/router';
import { FiArrowLeft } from 'react-icons/fi';
import goBack from '../../lib/utils/goBack';
import { IconButton } from '../Button';

export default function BackButton({ variant }) {
  const router = useRouter();
  return (
    <IconButton
      variant={variant}
      className="back-button"
      onClick={() =>
        goBack({
          router,
        })
      }
    >
      <FiArrowLeft className="w-6 h-6" />
      <span className="sr-only">Back</span>
    </IconButton>
  );
}
