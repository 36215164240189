import { useContext } from 'react';
import MessagesContext from './MessagesContext';

const useMessages = () => {
  const context = useContext(MessagesContext);

  if (context === undefined) {
    throw new Error('useMessages must be used within a MessagesContext.Provider');
  }

  return context;
};

export default useMessages;
