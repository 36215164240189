import React from 'react';

export default function EntityHeaderSekelton() {
  return (
    <section className="entity-header bg-gray-200 animate-pulse">
      <section className="wrapper">
        <section className="hero-content w-full">
          <section className="bg-gray-400 animate-pulse w-10 h-10 rounded-full mb-8" />
          <section className="space-y-2">
            <section className="bg-gray-400 animate-pulse w-2/5 h-10 rounded-full" />
            <section className="bg-gray-400 animate-pulse w-2/6 h-5 rounded-full" />
            <section className="bg-gray-400 animate-pulse w-36 h-12 rounded-lg" />
          </section>
        </section>
      </section>
    </section>
  );
}
