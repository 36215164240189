import Footer from './../../Footer';

const ContentLayout = ({ children }) => {
  return (
    <>
      <main className="page-content">
        <section className="page-wrapper">{children}</section>
      </main>
      <Footer />
    </>
  );
};

export const getLayout = (page) => <ContentLayout>{page}</ContentLayout>;

export default ContentLayout;
