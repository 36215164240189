import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

export default [
  {
    label: 'Instagram',
    link: 'https://instagram.com/FTVB_s',
    icon: <FaInstagram className="w-10 lg:w-6 h-10 lg:h-6" />,
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/FTVB_s',
    icon: <FaTwitter className="w-10 lg:w-6 h-10 lg:h-6" />,
  },
  {
    label: 'Facebook',
    link: 'https://www.facebook.com/FTVBs-The-Best-Film-and-TV-Buffs-Discussion-For-London-UK-Audience-759219471608870',
    icon: <FaFacebook className="w-10 lg:w-6 h-10 lg:h-6" />,
  },
  {
    label: 'Youtube',
    link: 'https://www.youtube.com/channel/UCyLjbCkVYXlaMmpvHDycvuA',
    icon: <FaYoutube className="w-10 lg:w-6 h-10 lg:h-6" />,
  },
];
