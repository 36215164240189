import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL || '';
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_KEY || '';

export const getSupabase = async () => {
  const { createClient: create } = await import('@supabase/supabase-js');

  return create(supabaseUrl, supabaseKey);
};

export const PER_PAGE = 49;

export const supabase = createClient(supabaseUrl, supabaseKey);
