const cantGoBack = ({ asPath }) =>
  (!window?.history?.state?.idx && window?.history?.state?.as === asPath) ||
  window?.history?.state?.as.includes('access_token');

const goBack = ({ router }) => {
  if (cantGoBack(router)) {
    return router.push('/discover');
  }
  return router.back();
};

export default goBack;
