import classNames from 'classnames';
import UserProfile, { UserProfileSkeleton } from '../../UserProfile';
import Modal from '../Modal';

export default function UsersModal({ isOpen, title, onClose, users, isLoading }) {
  const wrapperClasses = classNames('p-4 bg-white rounded-lg shadow-lg space-y-2');
  return (
    <Modal isOpen={isOpen} transparent onClose={onClose}>
      <section className="pt-16">
        <section className={wrapperClasses}>
          <section className="border-b pb-2">
            <h1 className="font-bold">{title} room users</h1>
          </section>
          <section className="border rounded-lg bg-gray-50 space-y-4 overflow-y-scroll max-h-[50vh]">
            {isLoading && [...Array(5).keys()].map((i) => <UserProfileSkeleton key={i} />)}
            {!isLoading && users.map((user) => <UserProfile key={user.id} user={user} />)}
          </section>
        </section>
      </section>
    </Modal>
  );
}
