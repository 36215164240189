import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useEntity } from '../../../lib/entity';
import { usePage } from '../../../lib/page';
import { useUser } from '../../../lib/user';
import DiscussionCard, { DiscussionSkeleton } from '../Discussion/DiscussionCard';

function DiscussionSidebar() {
  const router = useRouter();
  const { discussionSlug } = router.query;

  const setHideSide = usePage().setHideSide;

  const discussions = useEntity().discussions;
  const activeDiscussionId = useEntity().activeDiscussionId;
  const isLoading = useEntity().state.isLoading;

  const {
    state: { user },
  } = useUser();

  const getDiscussions = () => {
    if (discussionSlug) {
      return discussions.filter((d) => d.slug !== discussionSlug);
    }
    if (activeDiscussionId && user.id) {
      return discussions.filter((d) => d.id !== activeDiscussionId);
    }
    return discussions;
  };

  const allDiscussions = getDiscussions();

  useEffect(() => {
    if (!isLoading) {
      setHideSide(true);
    }
  }, [isLoading, setHideSide]);

  return (
    <>
      <section className="section">
        <section className="item">
          <h2 className="title">Live discussions:</h2>
          {router.isFallback && [...Array(3).keys()].map((i) => <DiscussionSkeleton key={i} />)}
          {allDiscussions.map((discussion) => (
            <DiscussionCard key={discussion.id} discussion={discussion} />
          ))}
          {!allDiscussions.length && (
            <h3>
              💭
              <br />
              No {`${activeDiscussionId ? 'other ' : ''}`}discussions right now
            </h3>
          )}
        </section>
      </section>
    </>
  );
}

// DiscussionSidebar.whyDidYouRender = true;
export default DiscussionSidebar;
