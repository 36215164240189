import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useAlert } from '../../../../lib/alert';
import { useEntity } from '../../../../lib/entity';
import { getSupabase } from '../../../../lib/supabase';
import { uploadFile } from '../../../../lib/supabase/actions';
import { useUser } from '../../../../lib/user';
import resizeMe from '../../../../lib/utils/resizeMe';
import slugify from '../../../../lib/utils/slugify';
import Button, { IconButton } from '../../../Button';
import FileInput from '../../../FormFields/FileInput/FileInput';
import TextInput from '../../../FormFields/TextInput';
import { Spinner } from '../../../Icons';
import Modal from '../../../Modals/Modal';

const errorsInitialState = {
  title: '',
  created_by: '',
  entity_id: '',
  room_photo: '',
};

function getSlug({ title, user, file }) {
  const fileExt = file.name.split('.').pop();

  return `${user.id}/rooms/${slugify(title)}-${user.username}.${fileExt}`;
}

export default function EditRoomForm({ isOpen, onUpdate, onClose, room }) {
  const router = useRouter();
  const user = useUser().state.user;

  const setRoom = useEntity().setRoom;
  const dispatch = useEntity().dispatch;

  const handleMessage = useAlert().handleMessage;

  const roomInitialState = useMemo(
    () => ({
      id: room.id || '',
      title: room.title || '',
      post_id: room.post_id || null,
      entity_id: room.entity_id || '',
      room_photo: room.room_photo || '',
      entity_photo: room.entity_photo || '',
      entity_title: room.entity_title,
      type: room.type,
    }),
    [
      room.id,
      room.post_id,
      room.entity_id,
      room.entity_photo,
      room.entity_title,
      room.room_photo,
      room.title,
      room.type,
    ],
  );
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(errorsInitialState);
  const [updateRoom, setUpdateRoom] = useState(roomInitialState);
  const [roomPhotoFile, setRoomPhotoFile] = useState(null);

  const hasChangedValues = Object.entries(updateRoom).reduce((output, [key, value]) => {
    if (room[key] !== value) {
      return true;
    }

    return output;
  }, false);

  const hasErrors = Object.values(errors).reduce((all, next) => (next ? !!next : all), false);

  const resetStates = () => {
    setErrors(errorsInitialState);
    setUpdateRoom(roomInitialState);
  };

  const validate = (key, value) => {
    let error = '';
    switch (key) {
      case 'title':
        if (value.length < 3) {
          error = 'Minimum length 3 characters';
        }

        if (value.length > 80) {
          error = 'Max length 80 characters';
        }
        break;
      default:
        break;
    }
    setErrors((prev) => ({ ...prev, [key]: error }));
  };

  const setData = (key, value) => {
    if (typeof key === 'string') {
      setUpdateRoom((prev) => ({ ...prev, [key]: value }));
    } else {
      setUpdateRoom(key);
    }
  };

  const handleBlur = (key, value) => {
    if (value) {
      validate(key, value);
    }
  };

  const handleFileChange = (file) => {
    const blobUrl = !file.url ? URL.createObjectURL(file) : file.url;

    // helper Image object
    const image = new Image();
    image.src = blobUrl;
    image.onload = () => {
      // have to wait till it's loaded
      const src = resizeMe(image); // send it to canvas
      setData('room_photo', src);
      const source = {
        src,
        file,
      };
      setRoomPhotoFile(source);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasChangedValues) {
      setProcessing(true);

      try {
        const { room_photo, post_id, ...uploadRoom } = updateRoom;
        let filePath = room_photo;
        if (roomPhotoFile) {
          const { file } = roomPhotoFile;
          const uploadeFilePath = getSlug({ title: updateRoom.title, user, file });
          const { publicURL } = await uploadFile({ file, filePath: uploadeFilePath });
          filePath = publicURL;
        }
        const supabase = await getSupabase();

        const { data: updatedRoom } = await supabase
          .from('rooms')
          .upsert({
            ...uploadRoom,
            room_photo: filePath,
            ...(post_id ? { post_id } : {}),
            created_by: user.id,
          })
          .single();

        if (updatedRoom) {
          global.analytics.track(
            'Updated a Room',
            {
              category: 'Rooms',
            },
            {
              page: {
                path: `/entity${updatedRoom.type}/x/rooms/update`,
                url: `${process.env.NEXT_PUBLIC_DOMAIN}entity${updatedRoom.type}/x/rooms/update`,
              },
              context: {
                ip: '0.0.0.0',
              },
            },
          );
          resetStates();
          setRoom(dispatch, { ...room, ...updatedRoom });
          if (onUpdate) {
            onUpdate();
          }
          if (
            updatedRoom.slug !== room.slug &&
            router.route === '/e/[type]/[entitySlug]/[roomSlug]'
          ) {
            await router.replace(
              `/e/${updatedRoom.type}/${slugify(updatedRoom.entity_title)}-${
                updatedRoom.entity_id
              }/${updatedRoom.slug}`,
            );
          }
          onClose();
          handleMessage({
            message: { message: 'Room updated', type: 'success' },
          });
        }
      } finally {
        setProcessing(false);
      }
    } else {
      resetStates();
      if (onUpdate) {
        onUpdate();
      }
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setErrors(errorsInitialState);
      setUpdateRoom(roomInitialState);
    }
  }, [isOpen, roomInitialState]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!processing) {
          onClose();
        }
      }}
      transparent
    >
      <section className="space-y-4 p-8 bg-white rounded-lg">
        <h1>Create room</h1>
        <form
          onSubmit={handleSubmit}
          name="Update room form"
          method="create"
          className="space-y-10"
        >
          <section className="space-y-4">
            <section className="flex flex-wrap w-full space-y-1">
              <TextInput
                className="w-full"
                inputClasses="border p-2"
                label="Room title"
                name="title"
                required
                longText
                errors={errors.title}
                value={updateRoom.title}
                onChange={(e) => setData('title', e.target.value)}
                onBlur={(e) => handleBlur('title', e.target.value)}
                disabled={processing}
              />
              <p className="text-sm text-gray-500">{updateRoom.title.length}/80</p>
            </section>
            <section className="flex flex-wrap w-full">
              {updateRoom.room_photo && (
                <section className="relative">
                  {processing && <Spinner className="absolute left-2 top-2" />}
                  <IconButton
                    className="absolute right-2 top-2 rounded-full bg-black/40 hover:bg-black"
                    variant="light"
                    onClick={() => {
                      setData('room_photo', '');

                      setRoomPhotoFile(null);
                    }}
                    disabled={processing}
                  >
                    <IoClose className="w-6 h-6" />
                  </IconButton>
                  <img
                    className="w-full h-full max-h-96 object-contain rounded-lg border"
                    src={updateRoom.room_photo}
                    layout="fill"
                    alt="Room"
                  />
                </section>
              )}
              {!updateRoom.room_photo && (
                <FileInput
                  className="file-input"
                  inputClasses="border p-2"
                  label="Update room photo"
                  name="room_photo"
                  errors={errors.room_photo}
                  value={updateRoom.room_photo}
                  onChange={handleFileChange}
                  disabled={processing}
                  accept="image/webp,image/png,image/gif,image/jpeg"
                />
              )}
            </section>
          </section>
          <Button disabled={hasErrors || processing} type="submit">
            Update room
          </Button>
        </form>
      </section>
    </Modal>
  );
}
