import { getSupabase } from '../../supabase';

export async function downloadImage(path) {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase.storage.from('photos').download(path);
    if (error) {
      throw error;
    }
    const url = URL.createObjectURL(data);
    return { url };
  } catch (error) {
    console.log('Error downloading image: ', error.message);
    return { error };
  }
}

export async function uploadFile({ file, filePath }) {
  const supabase = await getSupabase();

  try {
    const { error: uploadError } = await supabase.storage
      .from('media')
      .upload(filePath, file, { upsert: true });

    if (uploadError) {
      throw uploadError;
    }

    const { publicURL } = supabase.storage.from('media').getPublicUrl(filePath);

    return {
      publicURL,
      error: null,
    };
  } catch (error) {
    console.log('Error uploading image: ', error);

    return { error, publicURL: null };
  }
}
