export default function DiscussionSkeleton() {
  return (
    <section className="w-full border shadow rounded-lg p-4 space-y-4 animate-pulse">
      <section className="flex items-center justify-between">
        <section className="flex items-center">
          <section className="w-8 h-8 bg-gray-200 rounded-full mr-4" />
          <section className="w-20 h-5 bg-gray-200 rounded" />
        </section>
        <section className="w-20 h-10 bg-gray-200 rounded" />
      </section>
      <section className="w-10/12 h-3 bg-gray-200 rounded" />
      <section className="flex justify-around">
        <section className="w-11 h-11 bg-gray-200 rounded-full mr-2" />
        <section className="w-11 h-11 bg-gray-200 rounded-full mr-2" />
        <section className="w-11 h-11 bg-gray-200 rounded-full mr-2" />
        <section className="w-11 h-11 bg-gray-200 rounded-full mr-2" />
        <section className="w-11 h-11 bg-gray-200 rounded-full mr-2" />
      </section>
      <section className="w-2/12 h-3 bg-gray-200 rounded" />
    </section>
  );
}
