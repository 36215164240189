import { useEffect } from 'react';
import { getSupabase } from '../../../supabase';
import { getDiscussion } from '../../../supabase/actions';

export default function useDiscussionListeners({ ids, byId, actions }) {
  useEffect(() => {
    let discussionListeners;
    (async () => {
      const supabase = await getSupabase();

      // Listen for new discussions
      discussionListeners = supabase
        .from('discussions')
        .on('INSERT', async (payload) => {
          const slug = payload.new.slug;
          const { discussion: apiDiscussion } = await getDiscussion({
            slug,
          });
          actions.addEntity({
            discussion: apiDiscussion,
          });
        })
        .on('UPDATE', (payload) => {
          const foundRoom = ids.reduce((all, id) => {
            const roomsState = byId[id].rooms || { byId: {}, ids: [] };
            if (!all.id && roomsState.byId[payload.new.room_id]) {
              return roomsState.byId[payload.new.room_id];
            }
            return all;
          }, {});
          if (foundRoom.id && payload.new.ended) {
            actions.removeEntity({
              roomId: foundRoom.id,
              entityId: foundRoom.entity_id,
              discussion: payload.new,
            });
          }
        })
        .subscribe();
    })();

    // Cleanup on unmount
    return () => {
      if (discussionListeners) {
        discussionListeners.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byId, ids]);
}
