import { createContext } from 'react';
import { initialState } from './notificationsReducer';

const NotificationsContext = createContext({
  state: initialState(),
  dispatch: () => {},
  notifications: [],
});

export default NotificationsContext;
