import { userDetails } from '../../sql';
import { getSupabase, PER_PAGE } from '../../supabase';

export const getRooms = async ({ select, eq = {}, rangeFrom = 0, rangeTo = PER_PAGE }) => {
  const supabase = await getSupabase();
  let rooms = [];
  let err = null;

  try {
    if (eq.param) {
      const { data, error } = await supabase
        .from('rooms_with_discussions')
        .select(select)
        .eq(eq.param, eq.value)
        .order('inserted_at', { ascending: false })
        .range(rangeFrom, rangeTo);

      err = error;
      rooms = data || [];
    } else {
      const { data, error } = await supabase
        .from('rooms_with_discussions')
        .select(select)
        .order('inserted_at', { ascending: false })
        .range(rangeFrom, rangeTo);

      err = error;
      rooms = data || [];
    }

    if (err) {
      console.error('🥵 ~ error', err);
    }

    return { rooms, error: err };
  } catch (error) {
    return { error, rooms: [] };
  }
};

export const getEntityRooms = async ({ entityId, select, rangeFrom = 0, rangeTo = PER_PAGE }) => {
  const supabase = await getSupabase();

  try {
    const { data, error } = await supabase
      .from('rooms_with_discussions')
      .select(select)
      .order('inserted_at', { ascending: false })
      .eq('entity_id', entityId)
      .eq('discussions.ended', false)
      .range(rangeFrom, rangeTo);

    const rooms = data || [];

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { rooms, error };
  } catch (error) {
    return { error, rooms: [] };
  }
};

export const getRoom = async ({ slug, select = `*, users:room_users(count)` }) => {
  const supabase = await getSupabase();

  let room = null;
  try {
    const { data, error } = await supabase
      .from('rooms_with_discussions')
      .select(select)
      .eq('slug', slug)
      .single();
    room = data;

    if (error) {
      console.error('🥵 ~ error', error);
    }

    return { room };
  } catch (error) {
    return { error, room: null };
  }
};

export const updateRoom = async ({ id, update }) => {
  const supabase = await getSupabase();

  try {
    const { body: room } = await supabase.from('rooms').update(update).match({ id });
    return { room };
  } catch (error) {
    return { error, room: {} };
  }
};

export const deleteRoom = async (roomId) => {
  const supabase = await getSupabase();

  try {
    const { data: room } = await supabase.from('rooms').delete().eq('id', roomId).single();

    return { room };
  } catch (error) {
    return { error, room: {} };
  }
};

export const getRoomUsers = async ({ roomId }) => {
  const supabase = await getSupabase();
  let users = [];
  try {
    const { data } = await supabase
      .from('room_users')
      .select(`user:users_selection_table!user_id(${userDetails}, bio)`)
      .eq('room_id', roomId);

    if (data) {
      users = data.map(({ user }) => user);
    }

    return { users, error: null };
  } catch (error) {
    return { error, users: [] };
  }
};

export const getRoomDiscussions = async ({ roomId }) => {
  const supabase = await getSupabase();
  try {
    const { data: discussions } = await supabase
      .from('discussion_with_engagements')
      .select()
      .eq('room_id', roomId)
      .not('stream_link', 'eq', null);

    return { discussions, error: null };
  } catch (error) {
    return { error, discussions: null };
  }
};
